import { ComponentRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, noop, Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, flatMap, map, share, take } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    BetshareSessionData,
    BetshareSessionDataErrors,
    BetshareSessionErrorType,
    BetShareUtilService,
    IBetShareConfig
} from '@cdux/ng-fragments';

import {
    AccountBalanceDataService,
    BetShareDataService,
    enumFeatureToggle,
    enumStatus,
    EventClickAttributeType,
    FeatureToggleDataService,
    IAccountBalance,
    IBetShareDetails,
    IBetShareJoinResponse,
    JwtSessionService,
    TranslateService,
} from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { BetShareBusinessService } from '../../shared/bet-slip/services/betshare.business.service';
import { SIDEBAR_LOADERS } from '../../shared/sidebar/enums/loader.enums';
import { SidebarService } from '../../shared/sidebar/sidebar.service';

import { IBetsharePortal } from '../interfaces';
import { BetshareOptInResponse } from '../models';
import { CduxAbstractBetShareComponent } from '../components/abstract.bet-share.component';
import { BetShareDetailsComponent } from '../components/details/bet-share-details.component';
import { BetShareDepositComponent } from '../components/deposit/bet-share-deposit.component';
import { BetShareSSNComponent } from '../components/ssn/bet-share-ssn.component';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FundingService } from '../../shared/funding/shared/services/funding.service';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';
import { FullpageRegistrationComponent } from 'app/shared/registration/fullpage-registration.component';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Injectable({
    providedIn: 'root'
})
export class BetShareService {
    private _isTournamentSelected: boolean;

    // Should Emit the Portal whenever it is Updated
    public portalChange: BehaviorSubject<IBetsharePortal> = new BehaviorSubject({
        portal: new ComponentPortal(BetShareDetailsComponent),
        component: 'details'
    });
    // Should Emit the BetshareSessionData whenever it is Updated
    public sessionChange:  ReplaySubject<BetshareSessionData> = new ReplaySubject(1);
    public responseChange: Subject<BetshareOptInResponse> =  new Subject();

    // Whether this is the first time this session is being loaded
    public initialLoad: boolean = true;

    // Used to Set the BetshareID
    public set betshareID(id: string) {
        if (this._betshareID !== id) {
            this._betshareID = id;
            this.updateDetails();
        }
    }

    // Used to Set the Active Portal to be displayed in the Container
    public set activePortal(portal: IBetsharePortal) {
        if (!this._activePortal || this._activePortal.component !== portal.component) {
            this._activePortal = portal;
            this.portalChange.next(portal);
        }
    }
    // Used to Retrieve the Active Portal to be displayed in the Container
    public get activePortal(): IBetsharePortal {
        return this._activePortal;
    }

    // Used to Retrieve the BetshareSessionData
    public get session(): BetshareSessionData {
        return this._session;
    }

    // Used to Retrieve the Active Component Ref
    public get activeRef(): ComponentRef<CduxAbstractBetShareComponent> {
        return this._activeRef;
    }

    public get enabled(): boolean  {
        return this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BETSHARE);
    }

    public get fpdEnabled(): boolean {
        return this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
    }

    // Data Stores
    private _betshareID: string;
    private _activePortal: IBetsharePortal;
    private _activeRef: ComponentRef<CduxAbstractBetShareComponent>;
    private _session: BetshareSessionData = new BetshareSessionData();

    constructor(
        private _environment: ENVIRONMENT,
        private _router: Router,
        private _zone: NgZone,
        private _balanceService: AccountBalanceDataService,
        private _betshareConfig: BetShareBusinessService,
        private _betshareService: BetShareDataService,
        private _featureToggleService: FeatureToggleDataService,
        private _mediaService: CduxMediaToggleService,
        private _myBetsBusinessService: MyBetsBusinessService,
        private _sessionService: JwtSessionService,
        private _sidebarService: SidebarService,
        private _translateService: TranslateService,
        private _betShareUtil: BetShareUtilService,
        private _fundingService: FundingService,
        private _tournamentSessionService: TournamentsSessionService

    ) {
        this._isTournamentSelected = this._tournamentSessionService.isTournamentSelected();
        if (this.enabled && this._sessionService.isLoggedIn()) {
            this._requestBalanceUpdate().subscribe();
        }

        // Update BetshareSessionData whenever an Authentication Change occurs
        this._sessionService.onAuthenticationChange
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe((isLoggedIn: boolean) => {
                if (this.enabled) {
                    this._updateRestriction();
                }
                if (!isLoggedIn) {
                    this._clearSessionData();
                }
            });
    }

    /**
     * Starts up the generic loading spinner.
     */
    public showSpinningLoader() {
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.BET_SHARE_SPINNER);
    }

    /**
     * Closes the generic loading spinner.
     */
    public hideSpinningLoader() {
        this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.BET_SHARE_SPINNER);
    }

    /**
     * What occurs on a successful funding.
     *
     * @type {string[]} messages
     */
    public onFundingSuccess(messages: string[]) {
        this._requestBalanceUpdate()
            .subscribe(() => {
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.BET_SHARE_SPINNER, {
                        delayMs: 5000,
                        messages: messages,
                        status: enumStatus.SUCCESS
                    },
                    () => {
                        this.optIn();
                    });
            },
            () => {
                this.goToDetails();
                this._session.errors.set(BetshareSessionErrorType.UNKNOWN, true);
                this.sessionChange.next(this.session);
                this.hideSpinningLoader();
            });
    }

    /**
     * What occurs on successful SSN update.
     */
    public onSSNStatus(success: boolean) {
        if (success) {
            // The SSN is updated, I need to update the user info so that I'm not validating against old info.
            this.session.userInfo = this._sessionService.getUserInfo();
            this.sessionChange.next(this.session);
            this.optIn(() => {
                this.goToDetails();
                this._session.errors.set(BetshareSessionErrorType.UNKNOWN, true);
                this.sessionChange.next(this.session);
            });
        } else {
            this.optOut();
            this.hideSpinningLoader();
        }
    }

    /**
     * Actions to call after successfully registering.
     * It updates the user's balance, and decides whether to opt in or fund.
     */
    public onRegistrationAuthentication(offer?: string) {
        this._requestBalanceUpdate().subscribe((balance: number) => {
            // Check for a Registration Offer Code
            /* istanbul ignore next */
            if (!offer) {
                const headerKey = 'bet-share-registration-success-header';
                const messageKey = 'bet-share-registration-success-message';
                const messages = [
                    this._translateService.translate(headerKey, this._environment.affiliateId + ''),
                    this._translateService.translate(messageKey, this._environment.affiliateId + '')
                ];
                this.optInOrFund(balance, undefined, messages);
            } else {
                const headerKey = 'bet-share-registration-success-header-woffer';
                const messageKey = 'bet-share-registration-success-message-woffer';
                const fundedMessages = [
                    this._translateService.translate(headerKey, this._environment.affiliateId + ''),
                    this._translateService.translate(messageKey + '-funded', this._environment.affiliateId + '', true, offer)
                ];
                const unfundedMessages = [
                    this._translateService.translate(headerKey, this._environment.affiliateId + ''),
                    this._translateService.translate(messageKey, this._environment.affiliateId + '', true, offer)
                ];
                this.optInOrFund(balance, fundedMessages, unfundedMessages);
            }
        },
        () => {
            this.goToDetails();
            this._session.errors.set(BetshareSessionErrorType.UNKNOWN, true);
            this.hideSpinningLoader();
        });
    }

    // Facade for Requesting the Betshare Data
    public updateDetails() {
        this._requestBetshareData();
    }

    // Reduce the Shares in the BetshareSessionData by 1
    public decrementShares() {
        if (this._session.shares > 1) {
            this._session.shares--;
            // Emit the Change
            this.sessionChange.next(this._session);
        }
    }

    // Increase the Shares in the BetshareSessionData by 1
    public incrementShares() {
        if (this._session.shares < this._session.details.availableShares) {
            this._session.shares++;
            // Emit the Change
            this.sessionChange.next(this._session);
        }
    }

    // Utilize the current BetshareSessionData to attempt to Join a BetShare Wager
    public optIn(errorCallback: any = noop): void {
        const response = new BetshareOptInResponse;

        // Verify we have User Info, otherwise assume Anonymous
        if (!this._session.userInfo) {
            response.errors.set(BetshareSessionErrorType.AUTHENTICATION, true);
        }

        // Verify we have Betshare Details, otherwise assume Invalid ID
        if (!this._session.details || !this._session.details.betShareId) {
            response.errors.set(BetshareSessionErrorType.INVALID, true);
        }

        // Verify our User is not requesting more shares than are available
        if (this._session.details && (this._session.shares > this._session.details.availableShares)) {
            response.errors.set(BetshareSessionErrorType.SHARES, true);
        }

        // Verify our User has enough Funds to place the Wager
        if (this._session.details && (this._session.userBalance < this._betShareUtil.getCostOfShares(this._session))) {
            response.errors.set(BetshareSessionErrorType.FUNDS, true);
        }

        // An unknown error occurred if we're not reporting success.
        if (this._session.details && this._session.details.responseStatus !== 'SUCCESS') {
            response.errors.set(BetshareSessionErrorType.UNKNOWN, true);
        }

        // Check if our User needs to provide Full SSN
        if (this._session.userInfo && this._session.userInfo.cssdLength < 9) {
            response.errors.set(BetshareSessionErrorType.SSN_REQUIRED, true);
        }

        this._getRestriction().subscribe((restricted) => {
            // Verify our User was not registered in a Restricted State
            if (restricted || this._session.userInfo && this._session.config && (this._session.config.restrictedStates.findIndex((v) => v.toLowerCase() === this._session.userInfo.state.toLowerCase()) > -1)) {
                response.errors.set(BetshareSessionErrorType.RESTRICTED, true);
            }

            /* istanbul ignore next */
            if (response.errors.hasErrors()) {
                // Handle Failure
                if (response.errors.get(BetshareSessionErrorType.AUTHENTICATION)) {
                    this.goToLogin();
                    response.errors = new BetshareSessionDataErrors();
                } else if (response.errors.get(BetshareSessionErrorType.FUNDS)) {
                    this.goToDeposit();
                    response.errors = new BetshareSessionDataErrors();
                } else if (response.errors.get(BetshareSessionErrorType.SSN_REQUIRED)) {
                    const useCB = !this.activePortal; // We need to use the callback, which should bring us back to betshare.
                    response.errors = new BetshareSessionDataErrors();
                    if (useCB) {
                        this.goToSSN();
                        errorCallback();
                    } else {
                        this._zone.run(() => {
                            this.goToSSN();
                        });
                    }
                }

                // Clear out errors so that I don't come back to an error on load.
                response.status = 'FAILURE';
                this.responseChange.next(response);
            } else {
                this.showSpinningLoader();
                // Submit Wager
                this._betshareService.joinBetShare(this._session.details.betShareId, this._session.shares).pipe(map((r: IBetShareJoinResponse) => {
                    if (r.responseStatus.toLowerCase() === enumStatus.SUCCESS) {
                        response.status = 'SUCCESS';
                        this.responseChange.next(response);
                        this.goToSuccessPanel();
                        this._myBetsBusinessService.refreshMyBetsCache(this._isTournamentSelected);
                    } else {
                        response.status = 'FAILURE';
                        // This will ensure we're not calling the request for details multiple times
                        // and consequently erasing the errors from the screen.
                        // LOOK AT BetShareDetailsContainerComponent -> ngAfterViewInit
                        this.initialLoad = true;
                        this._requestBalanceUpdate().pipe(
                            concatMap(() => this._requestBetshareData())
                        ).subscribe(arr => {
                            this.goToDetails();
                            errorCallback();
                            this.hideSpinningLoader();
                        });
                    }
                })).subscribe();
            }
        });
    }

    /**
     * This function is created for optIn call after full page deposit successful return
     * and utilize the local copy of the BetshareSessionData to join the share. It is found that
     * the betShareSessionData inside the betShareService is quite unstable that could change dynamically
     * along with the sessionChange event get triggered. Use the local copy of data will avoid this problem.
     * At the beginning of the function, a _requestBetshareData call is placed to update the details,
     * and also retrieve localStorage for user's previously saved number of share before making the optIn wager call
     * @param bsSession
     * @param errorCallback [optional]
     */
    public optInFromFullPageDeposit(bsSession: BetshareSessionData, errorCallback: any = noop) {
        const response = new BetshareOptInResponse;
        // Need to update details on the session object:
        this._requestBetshareData().subscribe((detailsResponse) => {
            if (detailsResponse && detailsResponse.length >= 2) {
                bsSession.config = detailsResponse[0]; // config element
                bsSession.details = detailsResponse[1]; // details element
                // Update the share number
                if (localStorage.getItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD)) {
                    bsSession.shares = +localStorage.getItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD);
                    localStorage.removeItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD);
                }
                // Verify we have User Info:
                if (!this._session.userInfo) {
                    response.errors.set(BetshareSessionErrorType.AUTHENTICATION, true);
                }
                // Verify we have Betshare Details, otherwise assume Invalid ID
                // if (!this._session.details || !this._session.details.betShareId) {
                if (!bsSession.details || !bsSession.details.betShareId) {
                    response.errors.set(BetshareSessionErrorType.INVALID, true);
                }
                // Verify our User is not requesting more shares than are available
                if (bsSession.details && (bsSession.shares > bsSession.details.availableShares)) {
                    response.errors.set(BetshareSessionErrorType.SHARES, true);
                }
                // Verify our User has enough Funds to place the Wager
                if (bsSession.details && (bsSession.userBalance < this._betShareUtil.getCostOfShares(bsSession))) {
                    response.errors.set(BetshareSessionErrorType.FUNDS, true);
                }
                // An unknown error occurred if we're not reporting success.
                if (bsSession.details && bsSession.details.responseStatus !== 'SUCCESS') {
                    response.errors.set(BetshareSessionErrorType.UNKNOWN, true);
                }
                // Check if our User needs to provide Full SSN
                if (this._session.userInfo && this._session.userInfo.cssdLength < 9) {
                    response.errors.set(BetshareSessionErrorType.SSN_REQUIRED, true);
                }

                this._getRestriction().subscribe((restricted) => {
                    // Verify our User was not registered in a Restricted State
                    if (restricted || this._session.userInfo && bsSession.config && (bsSession.config.restrictedStates.findIndex((v) => v.toLowerCase() === this._session.userInfo.state.toLowerCase()) > -1)) {
                        response.errors.set(BetshareSessionErrorType.RESTRICTED, true);
                    }

                    /* istanbul ignore next */
                    if (response.errors.hasErrors()) {
                        // Handle Failures
                        if (response.errors.get(BetshareSessionErrorType.AUTHENTICATION)) {
                            this.goToLogin();
                            response.errors = new BetshareSessionDataErrors();
                        } else if (response.errors.get(BetshareSessionErrorType.FUNDS)) {
                            this.goToDeposit();
                            response.errors = new BetshareSessionDataErrors();
                        } else if (response.errors.get(BetshareSessionErrorType.SSN_REQUIRED)) {
                            const useCB = !this.activePortal; // We need to use the callback, which should bring us back to betshare.
                            response.errors = new BetshareSessionDataErrors();
                            if (useCB) {
                                this.goToSSN();
                                errorCallback();
                            } else {
                                this._zone.run(() => {
                                    this.goToSSN();
                                });
                            }
                        }

                        // Clear out
                        response.status = 'FAILURE';
                        this.responseChange.next(response);
                    } else {
                        this.showSpinningLoader();
                        // Submit Wager
                        this._betshareService.joinBetShare(bsSession.details.betShareId, bsSession.shares).pipe(map((r: IBetShareJoinResponse) => {
                            if (r.responseStatus.toLowerCase() === enumStatus.SUCCESS) {
                                response.status = 'SUCCESS';
                                this.responseChange.next(response);
                                this.goToSuccessPanel();
                                this._myBetsBusinessService.refreshMyBetsCache(this._isTournamentSelected);
                            } else {
                                response.status = 'FAILURE';
                                // This will ensure we're not calling the request for details multiple times
                                // and consequently erasing the errors from the screen.
                                // LOOK AT BetShareDetailsContainerComponent -> ngAfterViewInit
                                this.initialLoad = true;
                                this._requestBalanceUpdate().pipe(
                                    concatMap(() => this._requestBetshareData())
                                ).subscribe(arr => {
                                    this.goToDetails();
                                    this.hideSpinningLoader();
                                });
                            }
                        })).subscribe();
                    }
                });
            } else {
                // Error case no detail
                response.errors.set(BetshareSessionErrorType.INVALID, true);
                response.status = 'FAILURE';
                this.responseChange.next(response);
            }
        });
    }

    // Handles the Opt Out including relevant abandonment tracking
    public optOut(): void {
        this._zone.run(() => {
            this._clearSessionData();
            this._router.navigate(['/program'])
                .then(() => {
                    this.hideSpinningLoader();
                });
        });
    }

    // Request the Betshare Details Component to be loaded in the Container
    public goToDetails() {
        this.activePortal = {
            portal: new ComponentPortal(BetShareDetailsComponent),
            component: 'details'
        };
    }

    public goToLogin() {
        this._sessionService.redirectLoggedInUserUrl = this._router.url.split('?')[0];
        this._sessionService.redirectInputs = {isBetShare: 'true'};
        this._sessionService.loginInfoFlow = {
                attrId: EventClickAttributeType.BETSHARE_FLOW,
                data: 0,
                timestamp: new Date().getTime()
            };
        this._sessionService.onLogin = () => {
            this._onLogin();
        };
        this._router.navigate(['/login'], {queryParams: this._sessionService.redirectInputs});
    }

    // Request the Betshare Login Component to be loaded in the Container
    public goToDeposit() {
        if (!this.fpdEnabled) {
            this.activePortal = {
                portal: new ComponentPortal(BetShareDepositComponent),
                component: 'deposit'
            };
        } else {
            // Go to details no so that we're not in some random state when we come back from deposit
            this.goToDetails();
            localStorage.setItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK, this._session.details.betShareId);
            localStorage.setItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD, this._session.shares.toString());
            this._setDepositReturnUrl();
            // passing in betShareCost less balance and sourceType to redirect:
            this._router.navigate(['/', 'deposit'], {
                queryParams: {
                    'betShareCost': (+this._betShareUtil.getCostOfShares(this._session) - this._session.userBalance).toFixed(2),
                    'betShare': 'true'
                }
            });
        }
    }

    // Request the Betshare Register Component to be loaded in the Container
    public goToRegister() {
        localStorage.setItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK, this.betshareID);
        localStorage.setItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD, this._session.shares.toString());
        this._setDepositReturnUrl();
        this.activePortal = {
            portal: new ComponentPortal(FullpageRegistrationComponent),
            component: 'register'
        };
    }

    // Request the Betshare SSN Component to be loaded in the Container
    public goToSSN() {
        this.activePortal = {
            portal: new ComponentPortal(BetShareSSNComponent),
            component: 'ssn'
        };
    }

    /**
     * Determine if the user has sufficient funds, and if so, optin. Otherwise, deposit.
     *
     * @param balance
     * @param optinMessages
     * @param depositMessages
     * @param callback
     */
    /* istanbul ignore next */
    public optInOrFund(balance: number, optinMessages?: string[], depositMessages?: string[], callback: any = noop) {
        const fn = (cb, messages) => {
            if (messages) {
                // If there's a message, resolve with the success animation.
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.BET_SHARE_SPINNER, {
                        delayMs: 4000,
                        messages: messages,
                        status: enumStatus.SUCCESS
                    },
                    () => {
                        cb();
                    });
            } else {
                // Without a message, let's just resolve the spinner.
                this.hideSpinningLoader();
                cb();
            }
        };

        // Check for a restricted state
        this._getRestriction().subscribe((restricted) => {
            if (restricted || this._session.userInfo && this._session.config && (this._session.config.restrictedStates.findIndex((v) => v.toLowerCase() === this._session.userInfo.state.toLowerCase()) > -1)) {
                this.session.errors.set(BetshareSessionErrorType.RESTRICTED, true);
                fn(this.goToDetails.bind(this, callback), optinMessages);
                callback();
            } else if (balance >= this._betShareUtil.getCostOfShares(this._session)) {
                fn(this.optIn.bind(this, callback), optinMessages);
            } else {
                if (!this.fpdEnabled) {
                    fn(this.goToDeposit.bind(this), depositMessages);
                    callback();
                } else {
                    // store return back Url to local storage:
                    localStorage.setItem(FullPageFundingConstants.FPD_DONE_BSHARE_BCK, this._session.details.betShareId);
                    // store current number of share to local storage, this is used for deposit successful callback to submit the betshare:
                    localStorage.setItem(FullPageFundingConstants.SHARE_SAVED_BEFORE_FPD, this._session.shares.toString());
                    this._setDepositReturnUrl();
                    // passing in betShareCost less balance and sourceType to redirect:
                    this._router.navigate(['/', 'deposit'], {
                        queryParams: {
                            'betShareCost': (+this._betShareUtil.getCostOfShares(this._session) - balance).toFixed(2),
                            'betShare': 'true'
                        }
                    });
                }
            }
        });
    }

    /**
     * Callback for the BasePortalOutlet to push Refs to
     *
     * @internal
     */
    public _portalHostAttached(ref: ComponentRef<CduxAbstractBetShareComponent>) {
        this._activeRef = ref;
    }

    // Makes a Request to the AccountBalanceDataService to get a User's Balance
    private _requestBalanceUpdate(): Observable<number> {
        return this._balanceService.requestAccountBalance().pipe(
            take(1),
            map((balance: IAccountBalance) => {
                this._session.userBalance = balance.Balance;
                this._session.userInfo = this._sessionService.getUserInfo();
                // Emit the Change
                this.sessionChange.next(this._session);

                return balance.Balance;
            }),
            share()
        );
    }

    // Determines if a user is restricted from opting into this wager
    private _updateRestriction(): Observable<boolean> {
        let observable: Observable<boolean>;
        this.session.userRestricted = null;
        if (this._sessionService.isLoggedIn()) {
            observable = this._betshareService.checkRestrictions(this._betshareID);
        } else {
            observable = of(null);
        }
        /* istanbul ignore next */
        return observable.pipe(
            map((unrestricted) => {
                let restricted;
                if (unrestricted != null) {
                    restricted = !unrestricted;
                }
                this.session.userRestricted = restricted;
                this.sessionChange.next(this.session);
                return restricted;
            })
        );
    }

    private _getRestriction(): Observable<boolean> {
        let observable: Observable<boolean>;
        if (this._sessionService.isLoggedIn() && this.session.userRestricted != null) {
            observable = of(this.session.userRestricted);
        } else if (this._sessionService.isLoggedIn()) {
            observable = this._updateRestriction();
        } else {
            observable = of(false);
        }
        return observable.pipe(take(1));
    }

    // Makes a Request to the BetShareDataService to get the IBetShareDetails
    private _requestBetshareData(): Observable<[IBetShareConfig, IBetShareDetails, boolean]> {
        const observables = [];
        observables.push(this._betshareConfig.getBetShareConfig());
        observables.push(this._betshareService.getBetShareDetails(this._betshareID));
        const observable = zip(...observables).pipe(
            take(1),
            map((value: [IBetShareConfig, IBetShareDetails]) => {
                const config = value[0];
                const details = value[1];

                this._session.config = config;
                this._session.details = details;


                // Verify we have Config
                if (!config) {
                    this.session.errors.set(BetshareSessionErrorType.CONFIG, true);
                }

                // Verify Details came Back
                if (!details.betShareId) {
                    this.session.errors.set(BetshareSessionErrorType.INVALID, true);
                }

                // Verify Wagers can bet placed on the Race
                if ((details.raceStatus && details.raceStatus.toLowerCase() !== 'open') || details.mtp <= +details.mtpTimeout) {
                    this.session.errors.set(BetshareSessionErrorType.RACE_STATUS, true);
                }

                // Verify Shares are Available
                if (details.availableShares <= 0) {
                    this.session.errors.set(BetshareSessionErrorType.SHARES, true);
                } else if (this._session.shares > details.availableShares) {
                    this.session.errors.set(BetshareSessionErrorType.SHARES, true);
                    this._session.shares = 1;
                }

                // Verify the Wager isn't Cancelled
                if (details.betShareStatusId === 'CANCELLED' || details.betShareStatusId === 'CANCELLING') {
                    this.session.errors.set(BetshareSessionErrorType.STATUS, true);
                }

                if (this._session.userInfo && this._session.config && (this._session.config.restrictedStates.findIndex((v) => v.toLowerCase() === this._session.userInfo.state.toLowerCase()) > -1)) {
                    this.session.errors.set(BetshareSessionErrorType.RESTRICTED, true);
                }

                // An unknown error occurred.
                if (details.responseStatus !== 'SUCCESS') {
                    this.session.errors.set(BetshareSessionErrorType.UNKNOWN, true);
                }

                // Emit the Change
                this.sessionChange.next(this._session);

                return value;
            }),
            flatMap((value: [IBetShareConfig, IBetShareDetails]) => {
                // Verify we aren't restricted only if bet share Id is valid
                let returnValue: [IBetShareConfig, IBetShareDetails, boolean];
                if (value[1].betShareId) {
                    return this._getRestriction().pipe(
                        take(1),
                        map(restricted => {
                            if (restricted) {
                                this.session.errors.set(BetshareSessionErrorType.RESTRICTED, true);
                                // Emit the Change
                                this.sessionChange.next(this._session);
                            }
                            returnValue = [value[0], value[1], restricted];
                            return returnValue;
                        })
                    )
                } else {
                    return of(<any> [value[0], value[1], false]);
                }
            }),
            share()
        );
        observable.subscribe();
        return observable;
    }

    // Clears out the BetshareSessionData
    private _clearSessionData() {
        this.portalChange = new BehaviorSubject({
            portal: new ComponentPortal(BetShareDetailsComponent),
            component: 'details'
        });
        this.sessionChange = new ReplaySubject(1);
        this._session = new BetshareSessionData();
        this.initialLoad = true;
    }

    private _onLogin(): void {
        /* istanbul ignore next */
        if (!!this._session.details) {
            this._requestBalanceUpdate()
                .subscribe((value: number) => {
                    this.optInOrFund(value, null, null, () => {
                        return this._router.navigate(['betshare', this._session.details.betShareId]);
                    });
                    this._sessionService.redirectLoggedInUserUrl = null;
                    this._sessionService.redirectInputs = null;
                    this._sessionService.onLogin = null;
                });
        } else {
            this._clearSessionData();
            this._router.navigate(['/'])
        }
    }

    public goToSuccessPanel(): void {
        let params ;
        if (this._mediaService.query('phone'))  {
            params = {notify: 'bet-success'};
        } else {
            params = {action: 'bet-success'};
        }
        this._clearSessionData();
        this._zone.run(() => {
            this._router.navigate(['/program'], {queryParams: params })
                .then(() => {
                    this.hideSpinningLoader();
                });
        });
    }

    /**
     * Set a general URL for deposit return after complete (this is majorly for non-success path, such as deposit cancelled, abandoned)
     * @private
     */
    private _setDepositReturnUrl() {
        // If current URL is not /login, then save return URL to fundingService, otherwise set BetShare home as return URL
        this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0] !== '/login'
            ? this._router.url.split('?')[0] : '/betshare/' + this._session.details.betShareId;
    }
}
