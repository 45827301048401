
export const LANG_ERRORCODES_NAME = 'errorcodes';

export const LANG_ERRORCODES_TRANS = {
  '1000': 'Your transaction cannot be processed through this payment system at this time. Please select other deposit option or call customer support for more help.', // DEP_SYS_UNAVAIL_ERROR
  '1001': 'You have reached your daily deposit amount limit via Credit Card. Please contact Player Services at %s to request a limit increase or try another Deposit Method to complete this transaction now.', // DAILY TRANS_LIMIT_ERROR
  '1001-chat': 'You have reached your daily deposit amount limit via Credit Card. Please %s to request a limit increase or try another Deposit Method to complete this transaction now.',
  '1002': 'You have reached your weekly deposit amount limit via Credit Card. Please contact Player Services at %s to request a limit increase or try another Deposit Method to complete this transaction now.',  // WEEKLY TRANSACTION LIMIT ERROR.
  '1002-chat': 'You have reached your weekly deposit amount limit via Credit Card. Please %s to request a limit increase or try another Deposit Method to complete this transaction now.',
  '1005': 'Invalid minimum amount.', // INVALID_MIN_AMT_ERROR
  '1007': 'Maximum deposit amount exceeded.', // MAX_DEPOSIT_AMT_EXCEEDED, OVER_CAP_AMT_ERROR
  '1015': 'Maximum deposit amount exceeded.', // MAX_INITIAL_DEPOSIT_ERROR
  '1016': 'Minimum deposit amount not exceeded.', // MIN_DEPOSIT_AMT_EXCEEDED
  '1008': 'Tote is down.', // TOTE_DOWN_ERROR
  '1012': 'There was an error processing your request, please try again or use a different method.', // BIN CHECK FAILED
  'deposit-1008': 'Deposit successful! Current balance is unavailable.', // TOTE_DOWN_ERROR
  'withdraw-1008': 'We are unable to process withdrawals at this time. Please try again later.', // TOTE_DOWN_ERROR
  '2005': 'Property cannot be null.', // ACCNUM_EMPTY_ERROR, PIN_EMPTY_ERROR, AMOUNT_EMPTY_ERROR
  '2007': 'Property is invalid.', // INVALID_ACCNUM, INVALID_PIN, INVALID_AMOUNT
  '2017': 'Invalid AffiliateId.', // AFFILIATE_ERROR
  '2100': 'No bank information is available.', // NO_BANK_DETAILS_ERROR
  '2101': 'Banking information has not been verified.', // BANK_NOT_VERIFIED
  '2102': 'Initial deposit check failed.', // INITIAL_DEPOSIT_ERROR
  '2103': 'Pending deposit check failed.', // PENDING_DEPOSIT_ERROR
  '2104': 'EZmoney activation is temporarily disabled.', // EZ_ACTIVE_STATUS_ERROR
  '2105': 'Unable to activate EZmoney, waiting on bank.', // EZ_BANK_DELAY_ERRORS
  '2106': 'Your EZmoney deposit failed due to account setup & validation error. Contact %s or Giact Support (1-866-918-2409) for assistance.', // EZ_CHK_VALIDATION_ERROR
  '2106-chat': 'Your EZmoney deposit failed due to account setup & validation error. Please %s or contact Giact Support (1-866-918-2409) for assistance.',
  '2107': 'Northstar System is busy, try again later.', // NS_SYS_BUSY_ERROR
  '2109': 'Your earlier pending ACH transactions need to be successfully processed by the bank to be able to change your account.',
  '2110': 'Your earlier pending ACH transactions need to be successfully processed by the bank to be able to change your account.',
  '2112': 'We are currently verifying your initial deposit. Once your deposit has been verified you will be able to make withdrawals through EZ Money. This process may take 3-5 business days.', // EZMW_NO_DEPOSITS_MADE_YET
  '2113': 'We are currently verifying your initial deposit. Once your deposit has been verified you will be able to make withdrawals through EZ Money. This process may take 3-5 business days.', // EZMW_NO_PRIOR_SUCCESSFUL_DEPOSITS
  '2114': 'Your earlier pending ACH transactions need to be successfully processed by the bank to be able to make any further deposits.', // PENDING_EZMONEY
  '2115': 'Invalid funds.',
  '2129': 'Maximum deposit limit exceeded.', // VELOCITY_LIMIT_EXCEEDED
  '2131': 'Transaction cancelled due to an invalid linked PayPal account.',
  '2131-details': 'An account cannot be linked to multiple PayPal IDs. Please try again using PayPal ID: %s',
  '2133': 'Transaction cancelled due to an invalid linked PayPal account.',
  '2133-details': 'The PayPal ID is already linked to another account. Please use a different PayPal ID.',
  '2200': 'There was an error processing your request, please try again or use a different method.',
  '2201': 'There was an error processing your request, please try again or use a different method.',
  '2202': 'There was an error processing your request, please try again or use a different method.',
  '2203': 'There was an error processing your request, please try again or use a different method.',
  '2204': 'Your financial institution has indicated that the selected account does not have sufficient funds to cover the transaction. Please check your account balance, deposit amount, or try a different account/funding method.',
  '2205': 'We are unable to process your transaction. Please re-verify your bank account or use a different funding method.',
  '2205-action-text': 'Login to Bank',
  '2207': 'We cannot process your deposit with your selected method, please try a different deposit method.',
  '2207-action-text': 'Close',
  '2208': 'We cannot process your deposit with your selected method, please try again.',
  '2209': 'Please try a different bank account or deposit method.',
  'plaid-fallback': 'We cannot process your deposit with your selected method, please try again.',
  'player-services': 'If you have any questions, contact us at %s, or call %s',
  'DECLINED': 'There was an error processing your request, please try again or use a different funding method.',
  'EXPIRED CARD': 'Your card has expired. Please add a new card or choose another deposit method.',
  'fallback': 'There was an error processing your request, please try again or use a different method.',
  'plaid-withdraw-fallback': 'We cannot process your withdrawal with your selected method, please try again.',
  'offer-optout-warning': 'You will forfeit these bonuses if you withdraw funds before completing them:',
  'atm-debit-time-out': 'The deposit cannot be processed at this time. Please try again or use a different method.',
  'credit_card-pending-full-screen': 'Processing Credit Card<br/>We are still processing your credit card transaction. This is taking longer than expected.</p>',
  'credit_card-pending': 'We are still processing your credit card transaction. This is taking longer than expected.',
  'ezbank-99': 'If you would like to deposit funds, please select a deposit method.', // CANCEL_TRXN
  'ezbank-2': 'No funds have been withdrawn from your bank account. If you would like to deposit funds, please select another deposit method or contact Customer Support for help.', // ACCT_BLOCKED_ERROR
  'ezbank-4': 'The amount you specified exceeds the allowable limits. Please contact Customer Support for help.', // MAZ_TRXN_LIMIT_EXCEEDED
  'ezbank-15': 'No funds have been withdrawn from your bank account. If you would like to deposit funds, please select another deposit method or contact Customer Support for help.', // PROFILE_MISMATCH
  'ezbank-16': 'We are unable to process your payment due to insufficient funds in your bank account. If you would like to deposit funds, please select another deposit method or contact Customer Support for help.', // NON_SUFF_FUNDS
  'ezbank--1': 'Please check your updated Account Balance after a few seconds or contact Customer Support for help.', // UNKNOWN_ERROR // POLL_BREAK_ON_UNKNOWN_STATUS
  'ezbank--2': 'Your deposit is complete.', // TOTE_SUCCESS // TXN_STATUS_SUCCESS_AUTHORIZEFAILED
  'ezbank--3': 'Your deposit is complete.', // TOTE_SUCCESS // TXN_STATUS_SUCCESS_DEPOSITED
  'ezbank--4': 'Your deposit is complete. However your wagering account has not yet been updated. Please check back later for your updated account balance or contact Customer Support for help.', // TOTE_FAILED_QUEUED // TXN_STATUS_SUCCESS_TOTEFAILUR_OR_QUEUED
  'ez_money-pending-full-screen': 'Processing EZMoney<br/>We are still processing your EZMoney transaction. This is taking longer than expected.</p>',
  'ez_money-pending': 'We are still processing your EZMoney transaction. This is taking longer than expected.',
  'locked': 'We were unable to process your deposit. Your account may be setup incorrectly. Please contact customer service at <br /> %s.', // Method locked message
  'locked-chat': 'We were unable to process your deposit. Your account may be setup incorrectly. Please %s.',
  'locked-withdraw': 'We were unable to process your withdrawal. Please contact customer service at %s.',
  'locked-withdraw-chat': 'We were unable to process your withdrawal. Please %s.',
  'paynearme-unavailable': 'PayNearMe is currently unavailable.<br/>Please select another payment method or contact player services at %s.',
  'paynearme-unavailable-chat': 'PayNearMe is currently unavailable. Please select another payment method or %s.'
};
