export enum uiDisplayEnum {
    PLAID = 'PLAID',
    ACCOUNT_INFO_OR_REPLACE = 'ACCOUNT_INFO_OR_REPLACE',
    EXISTING_CARD = 'EXISTING_CARD',
}

export enum buttonTextEnum {
    DEPOSIT_AND_BET = 'Deposit & Bet',
    DEPOSIT = 'Deposit',
    LOGIN_TO_BANK = 'LOGIN TO BANK',
    WITHDRAW = 'Withdraw',
}
