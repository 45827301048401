<div  *cduxLoading="'completedBetsOverlay'; strategy:'overlay'; component:loadingDotsComponent;">
    <div class="complete-bets-container" [class.todays-bets-container-overrides]="todaysBetsContainer">
        <div *ngIf="isError" class="my-bet-empty">
            <div class="waiting-room">
                <h2><i class="icon-exclamation"></i>{{ comBetsErrorMessageHeader }}</h2>
                <p>{{ comBetsErrorMessage }} 
                </p>
                <p>Thank you for your patience!
                </p>
            </div>
        </div>
        <div *ngIf="completedBetsList.length === 0 && isLoaded">
            <div class="complete-bets-date-bar">{{ todaysBetsContainer ? 'Ended' : 'Today' }}</div>
            <div class="complete-bet-empty">
                <p>You have no completed bets</p>
            </div>
        </div>

        <div *ngFor="let betList of completedBetsList; let k = index">
            <div class="complete-bet-list-for-date">
                <!-- TODO: Expand fullDate filter for "Today", "Pending", and "Yesterday" (see tsui/widgets/common/dates/scripts/filters/date-label.js) -->
                <div class="complete-bets-date-bar">{{ betList.isFrozen === true ? 'Pending' : (betList.isToday ? 'Today' : betList.date | date:'fullDate') }}</div>
                <cdux-completed-bets *ngFor="let bet of betList.bets; let i = index"
                                     [bet]="bet"
                                     [hasFullSsn]="hasFullSsn"
                                     [isVideoToggledOn] = "isVideoToggledOn"
                                     [index]="i"
                                     (expand)="toggleExpandedView($event)"
                                     (contract)="contractSize()"
                                     [isToday]="betList.isToday"
                                     id="my-ended-bet-{{i+1}}"
                                     [isFinishOrderToggledOn] = "isFinishOrderToggledOn"
                                     [programView]="programView"
                                     [todaysBetsContainer]="todaysBetsContainer"></cdux-completed-bets>
            </div>
        </div>
        <div *ngIf="isIVRPhoneMessageToggledOn && isLoaded && affiliateId === 4200" class="phone-ivr-message">
          Wagers placed over phone or IVR will not be<br>
          shown until the race is official
        </div>
        <div *ngIf="isIVRPhoneMessageToggledOn && isLoaded && affiliateId !== 4200" class="phone-ivr-message">
          IVR bets will not be shown until the race is official
        </div>
    </div>
</div><!-- End complete-bet-list -->
