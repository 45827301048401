import { Component, Input } from "@angular/core";
import { ConfigurationDataService, EventClickType, FeatureToggleDataService, ISummaryRaceStats, enumConfigurationStacks, enumFeatureToggle } from "@cdux/ng-common";
import { DisplayModeEnum } from "app/shared/common";
import { EventTrackingService } from "app/shared/event-tracking/services/event-tracking.service";

@Component({
    selector: 'cdux-program-summary-racestats',
    templateUrl: './program-summary-racestats.component.html',
    styleUrls: ['./program-summary-racestats.component.scss'],
})
export class ProgramSummaryRacestatsComponent {
    private readonly SUMMARY_FEEDBACK_SURVEY = 'summary_feedback_survey'
    public isCompact = false;
    public DisplayModeEnum = DisplayModeEnum;
    public parsDistance: string;
    public raceDescriptionType: string;
    public raceType: string;
    public isSummarySurveyEnabled: boolean = false
    private _displayMode;
    private _summaryRaceStats: ISummaryRaceStats;

    constructor(
        private _featureToggleService: FeatureToggleDataService,
        private _eventTrackingService: EventTrackingService,
        private _configurationService: ConfigurationDataService,

    ){
        this.isSummarySurveyEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SUMMARY_SURVEY)
    }

    @Input()
    public set displayMode(displayMode: DisplayModeEnum) {
        this.isCompact = displayMode === DisplayModeEnum.COMPACT;
        this._displayMode = displayMode;
    }
    public get displayMode(): DisplayModeEnum {
        return this._displayMode;
    }
    
    @Input()
    public set summaryRaceStats(stats: ISummaryRaceStats) {
        if (stats) {
            const distance = stats.distance;
            this.parsDistance = (distance / 100).toFixed(0) + " " + stats.distanceUnit;

            //TODO: Consider moving this to the html template
            this.raceType = stats.simulcastBRISCode + " " + stats.ageRestriction + " " + stats.raceType + " " + stats.surfaceDescription + " " + this.parsDistance
        }
        this._summaryRaceStats = stats;
    }
    public get summaryRaceStats(): ISummaryRaceStats {
        return this._summaryRaceStats;
    }

    public formatDate(date: string): string {
        if (date && date.length >= 8) {
            const remainingDigits = date.substring(4);
            return `${remainingDigits.substring(0, 2)}/${remainingDigits.substring(2)}`;
        }
        return '';
    }

    public openSurvey() {
        if(this.isSummarySurveyEnabled === true)
        this._configurationService.getConfiguration(enumConfigurationStacks.TUX, [this.SUMMARY_FEEDBACK_SURVEY])
            .subscribe((configObj) => {
                if (configObj && configObj[this.SUMMARY_FEEDBACK_SURVEY]) {
                    window.open(configObj[this.SUMMARY_FEEDBACK_SURVEY]);
                }
                this._eventTrackingService.logClickEvent(EventClickType.SUMMARY_SURVEY);
            });
    }

    public formatClaimingRaceClassification(classification: string, price: number): string {
        if (!price || price == 0) {
            return classification;
        }
        return classification.replace(price.toString(),"");
    }
}
