import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { JwtSessionService } from '@cdux/ng-common';
import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {

    constructor(private sessionService: JwtSessionService, private router: Router) {}

    /**
     * The ActivatedRouteSnapshot contains the future route that will be
     * activated and the RouterStateSnapshot contains the future RouterState
     * of the application, should you pass through the guard check.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(this.sessionService.isLoggedIn()) {
            this.router.navigate([route?.data?.redirectUrl || DEFAULT_REDIRECT_PATH]);
           return false;
        } else {
            return true;
        }
    }
}
