import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, switchMap, startWith, distinctUntilChanged } from 'rxjs/operators';
import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';
import { IWager } from '@cdux/ng-fragments';
import { SidebarService } from '../../sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { map } from 'rxjs/operators';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';

@Component({
    selector: 'cdux-todays-bets-menu-item',
    templateUrl: './todays-bets-menu-item.component.html',
})
export class TodaysBetsMenuItemComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {
    /**
 * TODO: Provide a click event.
 *
 * @type [EventClickType}
 */
    protected clickEvent;

    /**
    * Used for determining when an external call is made to open the bets in a nav panel.
    * @type {MenuItemsEnum}
    */
    protected navTarget: MenuItemsEnum = MenuItemsEnum.TODAYS_BETS;

    public betCount$: Observable<number>;
    private destroy$ = new Subject<void>();
    private refresh$ = new Subject<void>();

    constructor(
            protected _eventTrackingService: EventTrackingService,
            protected _sidebarService: SidebarService,
            private _myBetsBusinessService: MyBetsBusinessService
        ) {
        super(_sidebarService, _eventTrackingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initializeBetCount();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeBetCount() {
        this.betCount$ = combineLatest([
            this._myBetsBusinessService.getIsTournamentSelected(),
            this._myBetsBusinessService.sessionService.onAuthenticationChange.pipe(startWith(null)),
            this.refresh$.pipe(startWith(null))
        ]).pipe(
            takeUntil(this.destroy$),
            switchMap(([isTournament, _]) =>
                this._myBetsBusinessService.getMyBetsCache(isTournament)
            ),
            map((bets: IWager[]) => bets.length),
            distinctUntilChanged()
        );
    }

    protected activate(): void {
        this.refresh$.next();
        this._sidebarService.loadComponent(TodaysBetsContainerComponent.getSidebarComponent(), null, {
            clearHistory: true,
        });
    }

    protected deactivate(): void {
        this._sidebarService.close(true);
    }

    public formatBetCount(count: number | null): string {
        if (count === null || count === undefined) {
            return '';
        }
        if (count > 999) {
            return '999+';
        }
        return count.toString();
    }
}
