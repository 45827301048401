import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, shareReplay, tap, withLatestFrom } from 'rxjs/operators';

import {
    BasicBetType,
    ConfigurationDataService,
    enumBetModifier,
    enumFeatureToggle,
    enumPoolType,
    enumRaceStatus,
    enumTrackType,
    EventClickAttributeType,
    EventClickType,
    ISelectedBetAmount,
    ITodaysRace,
    ITrackBasic,
    MultiRaceExoticBetType,
    PreferencesService,
    ProgramEntry,
    ToteDataService,
    TracksDataService,
    TrackService,
    WagerState,
    IBetNavObject,
    FeatureToggleDataService,
    JwtSessionService
} from '@cdux/ng-common';

import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { DisplayModeEnum } from 'app/shared/common/enums';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { EntriesManager } from 'app/shared/program/classes/entries-manager.class';
import { EntryUpdateHandler, IEntryUpdates } from 'app/shared/program/classes/entry-update-handler.class';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { RaceDetailsRequestHandler } from 'app/shared/betpad/classes/race-details-request-handler.class';
import { BetTypesRequestHandler } from 'app/shared/betpad/classes/bet-types-request-handler.class';
import { ViewableWagerDataHandler } from 'app/shared/betpad/classes/viewable-wager-data-handler.class';
import { IViewableWagerData } from 'app/shared/betpad/interfaces/viewable-wager-data.interface';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';
import { PreferencesComponent } from 'app/shared/my-account/preferences/preferences.component';
import { ExpertPickAnalysisComponent } from 'app/wagering/shared/angles/expert-pick-analysis/component/expert-pick-analysis.component';
import { BrisPicksComponent } from 'app/wagering/shared/bris-picks/bris-picks.component';
import { ProgramSummaryGlossaryComponent } from 'app/shared/program/components/program-summary/program-summary-glossary.component';
import { Router } from '@angular/router';

@Component({
    selector: 'cdux-program-section',
    templateUrl: './program-section.component.html',
    styleUrls: ['./program-section.component.scss']
})
export class ProgramSectionComponent extends AbstractWageringSectionComponent implements OnInit, OnDestroy {
    @ViewChild(ExpertPickAnalysisComponent, { read: ExpertPickAnalysisComponent, static: false })
    public expertPicksComponent: ExpertPickAnalysisComponent;

    @ViewChild(BrisPicksComponent, { read: BrisPicksComponent, static: false })
    public brisTipsComponent: BrisPicksComponent;

    @ViewChild(ProgramSummaryGlossaryComponent, { read: ProgramSummaryGlossaryComponent, static: false})
    public programSummaryGlossaryComponent: ProgramSummaryGlossaryComponent;

    public enumTrackType = enumTrackType;
    public get trackType(): enumTrackType {
        return this._track?.TrackType;
    }

    public get hasExpertPick(): boolean {
        return !!this._raceDetails?.hasExpertPick;
    }

    public get hasBrisPick(): boolean {
        return this._raceDetails?.hasBrisPick;
    }

    public get showCompactBrisPicks(): boolean {
        // use compact bris tips button on tips tab or any tab on small glass if expert picks are displayed
        return (this.displayMode !== DisplayModeEnum.LARGE || this.section === ViewSectionEnum.PROGRAM_TIPS)
            && (this.hasBrisPick && !this.isBrisPicksHidden)
            && (this.hasExpertPick && !this.isExpertPicksHidden);
    }

    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    private _betTypesRequestHandler: BetTypesRequestHandler;
    private _viewableWagerDataHandler: ViewableWagerDataHandler;

    private _betNavChange: ReplaySubject<IBetNavObject> = new ReplaySubject(1);
    private _wagerStateChange: ReplaySubject<WagerState> = new ReplaySubject(1);

    private _preferencesSubscription: Subscription;

    private _raceDetails: ITodaysRace;

    private _race: number = null;

    @Input()
    public set wagerState(state: WagerState) {
        this._wagerState = state;
        if (this.wagerState && this.wagerState.basicTrack && !(TrackService.isSameTrack(this._track, this._wagerState.basicTrack) && this._race === this._wagerState.basicTrack.RaceNum)) {
            this._track = this._wagerState.basicTrack;
            this._race = this._wagerState.basicTrack.RaceNum;

            this._entriesManager.updateRaceNavigation(state.basicTrack);
            this._raceDetailsRequestHandler.updateRaceNavigation(state.basicTrack);
            this._betTypesRequestHandler.updateBetTypes(state.basicTrack);
            this._viewableWagerDataHandler.updateRaceNavigation(state.basicTrack);
        }
        if (this.wagerState) {
            this._wagerStateChange.next(this.wagerState);
            if (this.wagerState.betNav) {
                this._betNavChange.next(this.wagerState.betNav)
            }
        }
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }

    @Input()
    public raceStatus: enumRaceStatus;

    @Input()
    public isRaceClosed: boolean;

    @Input() public displayMode: DisplayModeEnum;

    @Output()
    public onSelectionChange: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    @Output()
    public onResetEntrySelections: EventEmitter<undefined> = new EventEmitter<undefined>();

    @Output() public updateRaceNav = new EventEmitter<ITrackBasic>();
    @Output() public updateSection = new EventEmitter<ViewSectionEnum>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();

    public betnav: (BasicBetType | MultiRaceExoticBetType)[];

    /**
     * Is race thoroughbred and USA + Canada?
     */
    public get isTBNA(): boolean {
        return this.wagerState && this.wagerState.basicTrack &&
            this.wagerState.basicTrack.TrackType === enumTrackType.TBRED &&
            this._raceDetails && (this._raceDetails.country.toLowerCase() === 'usa' || this._raceDetails.country.toLowerCase() === 'can');
    }

    public isAnglesFTOn: boolean = false;

    public showScratches: boolean = false;
    public previousSection: ViewSectionEnum;

    public viewableData: IViewableWagerData;

    private _pinBetNav;
    public set pinBetNav(set: boolean) {
        this._pinBetNav = set;
        this._changeDetector.detectChanges();
    }
    public get pinBetNav(): boolean {
        return this._pinBetNav;
    }

    public isExpertPicksEnabled = false;
    public isExpertPicksHidden = false;
    public isBrisPicksEnabled = false;
    public isBrisPicksHidden = false;
    public isClassicEnabled = false;
    public isSpeedEnabled = false;
    public isPaceEnabled = false;
    public isSummaryEnabled = false;
    public isSummaryAuth = false;
    public isClassEnabled = false;
    public isCarryoversEnabled = false;
    public isProfitLineEnabled = false;

    public entriesObs: Observable<IEntryUpdates>;
    private _entries: ProgramEntry[][];
    private _entriesManager: EntriesManager;
    private _entriesUpdatesHandler: EntryUpdateHandler;

    constructor(
        protected _toteDataService: ToteDataService,
        private _eventTrackingService: EventTrackingService,
        private _tracksDataService: TracksDataService,
        private _changeDetector: ChangeDetectorRef,
        private _configurationService: ConfigurationDataService,
        private _preferencesService: PreferencesService,
        protected _viewStateService: ViewStateService,
        private _featureToggleService: FeatureToggleDataService,
        private _sessionService: JwtSessionService,
        private _router: Router
    ) {
        super(_toteDataService, _viewStateService);
        this._raceDetailsRequestHandler = new RaceDetailsRequestHandler(this._tracksDataService);
        this._betTypesRequestHandler = new BetTypesRequestHandler(this._tracksDataService);
        this._viewableWagerDataHandler = new ViewableWagerDataHandler(this._tracksDataService, this._configurationService);
        this.isClassicEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROGRAM_CLASSIC);
        this.isSpeedEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROGRAM_SPEED);
        this.isPaceEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROGRAM_PACE);
        this.isSummaryEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROGRAM_SUMMARY);
        this.isSummaryAuth = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SUMMARY_AUTH);
        this.isClassEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROGRAM_CLASS);
        this.isCarryoversEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.CARRYOVERS);
        this.isExpertPicksEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.EXPERT_PICKS);
        this.isExpertPicksHidden = this._preferencesService.getFlag(PreferencesComponent.HIDE_EXPERT_PICKS_KEY, false);
        this.isBrisPicksEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BRIS_PICKS);
        this.isBrisPicksHidden = this._preferencesService.getFlag(PreferencesComponent.HIDE_BRIS_PICKS_KEY, false);
        this.isProfitLineEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROFITLINE_ODDS);

        this._entriesManager = new EntriesManager(this._tracksDataService, this._betNavChange);
        this._entriesUpdatesHandler = new EntryUpdateHandler(this._wagerStateChange, this._entriesManager.listen());
        this.entriesObs = this._entriesUpdatesHandler.listen().pipe(
            tap((entries) => this._entries = entries?.updatedEntries || []),
            shareReplay(1)
        );
    }

    ngOnInit() {

        const sectionCache = this._viewStateService.getViewSectionGroupCache(ViewStateGroupEnum.PROGRAM);
        if (sectionCache && sectionCache !== this.section) {
            this.updateSection.emit(sectionCache);
        }

        if (this.section === ViewSectionEnum.CHANGES) {
            if (this.displayMode === DisplayModeEnum.LARGE) {
                // keep for enabling navigation to cratches
                this.showScratches = true;
            } else {
                // TODO: Remove when scratches enabled for TV
                this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
            }
        } else {
            switch (this.section) {
                case ViewSectionEnum.RUNNER_BALLOT:
                    if (!this.isClassicEnabled) {
                        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                    }
                    break;
                case ViewSectionEnum.PROGRAM_SPEED:
                    if (!this.isSpeedEnabled) {
                        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                    }
                    break;
                case ViewSectionEnum.PROGRAM_PACE:
                    if (!this.isPaceEnabled) {
                        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                    }
                    break;
                case ViewSectionEnum.PROGRAM_CLASS:
                    if (!this.isClassEnabled) {
                        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                    }
                    break;
                case ViewSectionEnum.PROGRAM_SUMMARY:
                    if (!this.isSummaryEnabled) {
                        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                    }
                    break;
            }
        }

        this._betTypesRequestHandler.listen().pipe(withLatestFrom(this._betNavChange))
            .subscribe(([betTypes, betNavObject]) => {
                this.betnav = betTypes;
            });

        this._raceDetailsRequestHandler.listen()
            .subscribe((raceDetails) => {
                this._raceDetails = raceDetails;
                this._changeDetector.markForCheck();
            });

        this._viewableWagerDataHandler.listen().subscribe(
            (data) => {
                this.viewableData = data;
                // navigate away if track doesn't support angles,speed,class and pace
                if ((this.section === ViewSectionEnum.PROGRAM_TIPS && !this.isTBNA) ||
                    (this.section === ViewSectionEnum.PROGRAM_SPEED && !(this.viewableData && this.viewableData.speed)) ||
                    (this.section === ViewSectionEnum.PROGRAM_CLASS && !(this.viewableData && this.viewableData.class)) ||
                    (this.section === ViewSectionEnum.PROGRAM_PACE && !(this.viewableData && this.viewableData.pace)) ||
                    (this.section === ViewSectionEnum.PROGRAM_SUMMARY && !(this.viewableData && this.viewableData.summary))
                ) {
                    this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
                }
            }
        );

        // TODO: update to use the watchFlag method?
        this._preferencesSubscription = this._preferencesService.flagUpdates.pipe(
            filter(({key}) =>
                key === PreferencesComponent.HIDE_EXPERT_PICKS_KEY ||
                key === PreferencesComponent.HIDE_BRIS_PICKS_KEY
            )
        ).subscribe(({key, value}) => {
            switch (key) {
                case PreferencesComponent.HIDE_EXPERT_PICKS_KEY:
                    this.isExpertPicksHidden = !!value
                    break;
                case PreferencesComponent.HIDE_BRIS_PICKS_KEY:
                    this.isBrisPicksHidden = !!value
                    break;
            }
            this._changeDetector.markForCheck();
        });
    }

    ngOnDestroy() {
        this._entriesManager.kill();
        this._entriesUpdatesHandler.kill();
        this._betTypesRequestHandler.kill();
        this._raceDetailsRequestHandler.kill();
        this._viewableWagerDataHandler.kill();
        this._preferencesSubscription.unsubscribe();
    }

    public handleSummaryAuth(): void {
        if (this.isSummaryAuth) {
            this.logClickEvent(ViewSectionEnum.PROGRAM_SUMMARY);
          if (!this._sessionService.isLoggedIn()) {
            // User is not logged in, prompt for login
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
          } else {
            // User is logged in, proceed to view summary
            this.viewSummary();
          }
        }
    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this.updateBetAmount.emit(betAmount);
    }

    public onBetNavChange(betNav: (BasicBetType | MultiRaceExoticBetType)[]) {
        this.betnav = betNav;
        this.updateBetNav.emit(betNav);
    }

    public isWagerableRace(): boolean {
        return TrackService.isWagerableRace(this.raceStatus);
    }

    public logClickEvent(view: ViewSectionEnum) {
        const ts = Date.now();

        switch (view) {
            case ViewSectionEnum.PROGRAM_BASIC:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_BASIC, [
                    { attrId: EventClickAttributeType.PROGRAM_BASIC_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_BASIC_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_BASIC_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_BASIC_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_ADVANCED:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_ADVANCED, [
                    { attrId: EventClickAttributeType.PROGRAM_ADVANCED_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_ADVANCED_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_ADVANCED_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_ADVANCED_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_SPEED:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_SPEED, [
                    { attrId: EventClickAttributeType.PROGRAM_SPEED_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_SPEED_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_SPEED_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_SPEED_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_CLASS:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_CLASS, [
                    { attrId: EventClickAttributeType.PROGRAM_CLASS_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CLASS_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CLASS_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CLASS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_PACE:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_PACE, [
                    { attrId: EventClickAttributeType.PROGRAM_PACE_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_PACE_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_PACE_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_PACE_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_TIPS:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_TIPS, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROGRAM_SUMMARY:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_SUMMARY);
                break;
            case ViewSectionEnum.CHANGES:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_NAV_CHANGES, [
                    { attrId: EventClickAttributeType.PROGRAM_CHANGES_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CHANGES_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CHANGES_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_CHANGES_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.RUNNER_BALLOT:
                this._eventTrackingService.logClickEvent(EventClickType.WAGER_MENU, [
                    { attrId: EventClickAttributeType.WAGER_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.WAGER_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.WAGER_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.WAGER_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            default:
            // We should never get in here, but if we do, that's OK, we won't log any click events
        }
    }

    public viewBasic() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_BASIC);
        this.logClickEvent(ViewSectionEnum.PROGRAM_BASIC);
    }

    public viewAdvanced() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
        this.logClickEvent(ViewSectionEnum.PROGRAM_ADVANCED);
    }

    public viewSpeed() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_SPEED);
        this.logClickEvent(ViewSectionEnum.PROGRAM_SPEED);
    }

    public viewPace() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_PACE);
        this.logClickEvent(ViewSectionEnum.PROGRAM_PACE);
    }

    public viewClass() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_CLASS);
        this.logClickEvent(ViewSectionEnum.PROGRAM_CLASS);
    }

    public viewAngles() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_TIPS);
        this.logClickEvent(ViewSectionEnum.PROGRAM_TIPS);
    }
    
    public viewSummary() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.PROGRAM_SUMMARY);
        if (!this.isSummaryAuth) {
            this.logClickEvent(ViewSectionEnum.PROGRAM_SUMMARY);
        }
    }

    public viewScratches() {
        this.showScratches = !this.showScratches;
        if (this.showScratches) {
            this.previousSection = this.section;
            this.logClickEvent(ViewSectionEnum.CHANGES);
            this.updateSection.emit(ViewSectionEnum.CHANGES);
        } else if (!this.previousSection) {
            this.updateSection.emit(ViewSectionEnum.PROGRAM_ADVANCED);
        } else {
            this.updateSection.emit(this.previousSection);
        }
    }

    public viewLinear() {
        this.showScratches = false;
        this.updateSection.emit(ViewSectionEnum.RUNNER_BALLOT);
        this.logClickEvent(ViewSectionEnum.RUNNER_BALLOT);
    }

    public top3Selection(update: ISelectionUpdate[], amount?: number) {
        if (Array.isArray(this.betnav)) {
            const exacta = this.betnav.find((betType) => betType && typeof betType.code === 'string' && betType.code.toUpperCase() === enumPoolType.EXACTA);
            if (exacta) {
                this.onResetEntrySelections.emit();
                this.onUpdateBetType(exacta);
                this.onUpdateBetModifier(enumBetModifier.BOX);
                if (amount) {
                    const ba = exacta.betAmounts.find(betAmount => +betAmount.value === amount);
                    if (ba) { this.onUpdateBetAmount(ba); }
                }
                update.forEach((upd, i) => { // expand first 3 entries to include shared interests
                    const bis = upd.entries.slice(0, 3).map(e => e.BettingInterest);
                    upd.entries = this._entries[i].filter((e) => bis.includes(e.BettingInterest));
                });
                this.onSelectionChange.next(update);
            }
        }
    }

    public redirectToResult() {
        if (this.isRaceClosed === true) {
            this.updateSection.emit(ViewSectionEnum.RESULTS);
        }
    }
}
