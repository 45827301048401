<ng-template cdk-portal #dropupPortal="cdkPortal">
    <cdux-datepicker-lite
        #datepicker
        *ngIf="isDatepickerDropupOpen"
        [startYear]="startYear"
        [isRange]="isAccountHistoryRangeFTOn"
        [selectedDate]="selectedDate"
        [selectedFromDate]="selectedDate"
        [selectedToDate]="selectedDate"
        [allowTodaySelection]="allowTodaySelection"
        [latestAllowedDate]="toteDate"
        (change)="onChangeDate($event)"
        (changeRange)="onChangeDateRange($event)">
    </cdux-datepicker-lite>
</ng-template>

<div class="account-history-container"
    [class.calendar-is-open]="isDatepickerDropupOpen"
    [cdkPortalOutlet]="dropupPortal">
    <!--Loading overlay displays in transaction area-->
    <!--Header-->
    <header
        *ngIf="!isAccountHistoryRangeFTOn"
        class="account-history-filter-bar"
        [cduxFocus]="!isLoaded"
        (click)="toggleCalendar()"
        id="account-history-sidebar-pick-date-header">
        <i class="icon-date-filter"></i>
        <div class="filter-label">Pick a date
            <div class="filter-label-date">{{selectedDate | date:'MMMM d'}}</div>
        </div>
    </header>
    <header
        *ngIf="isAccountHistoryRangeFTOn"
        class="account-history-filter-bar is-range"
        [cduxFocus]="!isLoaded"
        id="account-history-sidebar-date-range-header">
        <div
            class="filter-label"
            [class.is-selected]="predefinedSelection === predefinedOptions.TODAY"
            (click)="getTodaysTransactions()">
            Today
        </div>
        <div
            class="filter-label"
            [class.is-selected]="predefinedSelection === predefinedOptions.YESTERDAY"
            (click)="getYesterdaysTransactions()">
            Yesterday
        </div>
        <div
            class="filter-label"
            [class.is-selected]="predefinedSelection === predefinedOptions.LAST7DAYS"
            (click)="getLast7DaysTransactions()">
            7 Days
        </div>
        <div
            class="filter-label"
            [class.is-selected]="predefinedSelection === predefinedOptions.LAST30DAYS"
            (click)="getLast30DaysTransactions()">
            30 Days
        </div>
        <ng-container *ngIf="!isAccountHistoryFilterToggledOn; else filtersEnabled">
            <div class="filter-label"
                [class.is-selected]="predefinedSelection === predefinedOptions.CUSTOM"
                (click)="toggleCalendar()"
                id="account-history-date-range-link">
                Date Range
            </div>
        </ng-container>
        <ng-template #filtersEnabled>
            <div class="filter-label">&nbsp;</div>
            <div class="filter-label"
                (click)="toggleCalendar()"
                id="account-history-date-range-icon"><i class="calendar"></i>
            </div>
            <div class="filter-label" id="account-history-filter-icon">
                <cdux-account-history-filter [isTodaySelected]="isTodaySelected" (emitFilters)="updateFilters($event)"></cdux-account-history-filter>
            </div>
        </ng-template>
    </header>
    <div class="scrolling-area" cduxMoreContent (hasMoreContent)="toggleScrollingIndicator($event)">
        <div *cduxLoading="'accountHistoryOverlay'; strategy:'overlay'; component:loadingDotsComponent;" #scrollingArea>
            <div *ngIf="isError; else transactionList" class="complete-bet-empty">
                <div *ngIf="rangeError === rangeErrors.NONE" class="waiting-room">
                    <h2><i class="icon-exclamation"></i>{{ actHistoryErrorMessageHeader }}</h2>
                    <p>{{ actHistoryErrorMessage }}</p>
                    <p>Thank you for your patience!</p>
                </div>
                <div *ngIf="rangeError === rangeErrors.INVALID_DATES">
                    <div class="complete-bet-empty">
                        <p>Invalid date range.</p>
                    </div>
                </div>
                <div *ngIf="rangeError === rangeErrors.TOO_LONG">
                    <div class="complete-bet-empty">
                        <p>Range limit is maximum 1 year.</p>
                    </div>
                </div>
            </div>
            <ng-template #transactionList>
                <ng-container *ngIf="predefinedSelection === predefinedOptions.TODAY; else notToday">
                    <!--Pending Transaction Section-->
                    <div class="account-history-bet-list-for-date" *ngIf="pendingTransactions.length > 0">
                        <div class="account-history-date-bar">Pending</div>
                        <cdux-transaction-component
                            *ngFor="let transaction of pendingTransactions; let i = index; trackBy: trackByTransaction"
                            [transaction]="transaction"
                            [isToday]="isToteDay(transaction.transactionDate)"
                            [hasFullSsn]="hasFullSsn"
                            [isFinishOrderToggledOn]="isFinishOrderToggledOn"
                            [index]="i"
                            (expand)="toggleExpandedView($event)"
                            (contract)="resetSize()"
                            (wagerCanceled)="wagerCanceled($event)"
                        ></cdux-transaction-component>
                    </div>
                    <!--Completed Transaction Section-->
                    <div class="account-history-bet-list-for-date"
                        *ngIf="!isError && (pendingTransactions.length === 0 || completeTransactions.length > 0)">
                        <div class="account-history-date-bar">
                            {{isToday ? 'Today' : 'Ended'}}
                        </div>
                        <div *ngIf="completeTransactions.length === 0 && isLoaded">
                            <div class="complete-bet-empty">
                                <p>No transactions found.</p>
                            </div>
                        </div>
                        <cdux-transaction-component
                            *ngFor="let transaction of completeTransactions; let i = index; trackBy: trackByTransaction"
                            [transaction]="transaction"
                            [isToday]="isToteDay(transaction.transactionDate)"
                            [hasFullSsn]="hasFullSsn"
                            [isFinishOrderToggledOn]="isFinishOrderToggledOn"
                            [index]="i"
                            (expand)="toggleExpandedView($event)"
                            (contract)="resetSize()"
                        ></cdux-transaction-component>
                    </div>
                </ng-container>
                <ng-template #notToday>
                    <ng-container *ngIf="transactions.length === 0">
                        <ng-container [ngSwitch]="predefinedSelection">
                            <div *ngSwitchCase="predefinedOptions.YESTERDAY" class="account-history-date-bar">
                                {{ fromDate | date:'MMMM d, y'}}
                            </div>
                            <div *ngSwitchDefault class="account-history-date-bar">
                                {{ fromDate | date:'MMMM d, y'}} - {{ toDate | date:'MMMM d, y'}}
                            </div>
                        </ng-container>
                        <div class="complete-bet-empty">
                            <p>No transactions found.</p>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let category of objectKeys(transactionCategories)">
                        <div class="account-history-date-bar">
                            {{ transactionCategories[category][0].transactionDate | date:'MMMM d, y'}}
                        </div>
                        <cdux-transaction-component
                            *ngFor="let transaction of transactionCategories[category]; let i = index; trackBy: trackByTransaction"
                            [transaction]="transaction"
                            [isToday]="isToteDay(transaction.transactionDate)"
                            [hasFullSsn]="hasFullSsn"
                            [isFinishOrderToggledOn]="isFinishOrderToggledOn"
                            [index]="i"
                            (expand)="toggleExpandedView($event)"
                            (contract)="resetSize()"
                        ></cdux-transaction-component>
                    </ng-container>
                </ng-template>
                <div *ngIf="nextPage > 0" (click)="loadNextPage(nextPage)" class="load-more">
                    <div class="btn">
                        Load More Transactions
                    </div>
                </div>
                <div *ngIf="isIVRPhoneMessageToggledOn && isLoaded && affiliateId === 4200" class="phone-ivr-message">
                  Wagers placed over phone or IVR will not be<br>
                  shown until the race is official
                </div>
                <div *ngIf="isIVRPhoneMessageToggledOn && isLoaded && affiliateId !== 4200" class="phone-ivr-message">
                  IVR bets will not be shown until the race is official
                </div>
            </ng-template>
        </div>
    </div>

    <cdux-more-content-indicator *cduxMediaToggle="'desk-to-phone'" [moreContent]="showScrollingIndicator">
    </cdux-more-content-indicator>
    <!--Footer-->
    <footer class="prev-next-day-btns" *ngIf="isLoaded && !isAccountHistoryRangeFTOn">
        <div class="btn-account-history-prev-day" *ngIf="!isLastDay"
            [cduxLogClick]="eventClickType.ACCOUNT_HISTORY_PREVIOUS" (click)="previousDay()">Previous</div>
        <div class="btn-account-history-next-day" *ngIf="!isToday" [cduxLogClick]="eventClickType.ACCOUNT_HISTORY_NEXT"
             (click)="nextDay()">Next</div>
    </footer>
</div>
