import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { JwtSessionService } from '@cdux/ng-common';
import { LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private sessionService: JwtSessionService, private router: Router) {}

    /**
     * The ActivatedRouteSnapshot contains the future route that will be
     * activated and the RouterStateSnapshot contains the future RouterState
     * of the application, should you pass through the guard check.
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkLogin(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {
        if (this.sessionService.isLoggedIn()) {
            return true;
        } else {
            this.sessionService.redirectLoggedInUserUrl = url;
            this.router.navigate([ LOGIN_REDIRECT_PATH ]);
            return false;
        }
    }
}
