import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
    Bet,
    BetShareDataService,
    CduxObjectUtil,
    IBet,
    IBetResult,
    IBetTypeConstraint,
    JwtSessionService,
    OldWagerValidationService,
    ProgramEntry,
    TranslateService,
    UserEventEnum,
    WagerDataService,
    WagerService,
    IBetShare,
    IBetSlipResponse,
    TrackService,
    enumTrackType
} from '@cdux/ng-common';
import { ITransaction, IWager, ToastService } from '@cdux/ng-fragments';

import { BET_ERROR_CODES } from '../enums/bet-error-codes.enum';
import { BetSlipErrorsService } from './bet-slip-errors.service';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { CduxRouteUtil } from '../../common/utils/CduxRouteUtil';
import { MyBetsBusinessService } from '../../bets/services/my-bets.business.service';
import { TodaysRacesBusinessService } from '../../program/services/todays-races.business.service';
import { WageringViewEnum } from '../../../wagering/views/enums/wagering-view.enum';
import { CduxStorageService } from '@cdux/ng-platform/web';
import { CompletedBetsBusinessService } from 'app/shared/bets/services/completed-bets.business.service';
import { ICancelWagerInfo } from 'app/shared/bet-slip/interfaces/cancel-wager-info.interface';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

interface SharedBetProperties {
    amount: number;
    betType: string;
    race: string;
    success: boolean;
    track: string;
    wagerId: string;
    runList: string;
    conditional?: boolean;
    conditionalOdds?: number;
    conditionalMtp?: number;
    cost?: number;
    shares?: IBetShare;
    betShare?: boolean;
}



@Injectable()
export class BetsBusinessService {
    constructor(
                private _wagerService: WagerService,
                private _wagerDataService: WagerDataService,
                private _OldWagerValidationService: OldWagerValidationService,
                private _betShareDataService: BetShareDataService,
                private _betSlipErrorsService: BetSlipErrorsService,
                private _sessionService: JwtSessionService,
                private _eventTrackingService: EventTrackingService,
                private _myBetsService: MyBetsBusinessService,
                private _toastService: ToastService,
                private _translateService: TranslateService,
                private _route: ActivatedRoute,
                private _router: Router,
                private _todaysRacesService: TodaysRacesBusinessService,
                private _storageService: CduxStorageService,
                private _completedBetsService: CompletedBetsBusinessService,
                private _tournamentSessionService: TournamentsSessionService
    ) {
        // Empty
    }

    private _isTournamentSelected = this._tournamentSessionService.isTournamentSelected();

    public submitWager(bet: Bet, total: string, showCanceledBets = false ): Observable<IBetResult> {
        const submittableBet = bet.getSubmittableWager();

        return this._wagerService.placeWagers([submittableBet], this._sessionService.getUserInfo()).pipe(
            catchError((err) => {
                this.logWagerEvent(UserEventEnum.BET_FAILED, [submittableBet]);
                // Look at the error object that's returned for a description
                const requestErrorMessage: string = err.data ? CduxObjectUtil.deepGet(err, 'data.error.Error.Description') : err;

                // add geolocation errors to bets where appropriate
                if (this._wagerService.isGeolocationRequired(submittableBet, this._sessionService.getUserInfo())) {
                    this._betSlipErrorsService.setError(submittableBet.wagerId, BET_ERROR_CODES.GEOLOCATION_REQUIRED);

                // Expired JWT error occurs when logged in user places wager after
                // JWT expires but before being logged out. The subsequent error
                // message is not returned on the Bets array from the BetSlip call
                // so we need to check for it here and the BetSlipComponent.
                // See PR https://github.com/twinspires/cdux-ng/pull/1489.
                } else if (requestErrorMessage === BET_ERROR_CODES.EXPIRED_JWT) {
                    this._betSlipErrorsService.setError(bet.id, BET_ERROR_CODES.EXPIRED_JWT);
                    this.handleExpiredJwtError();
                }

                // rethrow the error downstream
                return throwError(err);
            }),
            map((betSlipResponse: IBetSlipResponse) => {
                if (betSlipResponse && betSlipResponse.bets[0]) {
                    this.logWagerEvent(UserEventEnum.BET_COMPLETE, betSlipResponse.bets, betSlipResponse.accountBalance);
                    return betSlipResponse.bets[0];
                } else {
                    return null;
                }
            }),
            // Process Errors into BetSlip Service
            tap((wagerResult: IBetResult) => {
                if (wagerResult && wagerResult.success) {
                    this._myBetsService.refreshMyBetsCache(this._isTournamentSelected, showCanceledBets );
                } else if (wagerResult && wagerResult.message) {
                    // The error codes coming back from Tote are the same for
                    //  MAX_EXCEEDED and MIN_NOT_MET, so we have to check when
                    //  we encounter this message which case we are actually in.
                    if (wagerResult.message === BET_ERROR_CODES.MAX_EXCEEDED) {
                        this._OldWagerValidationService.getBetConstraints(bet).pipe(
                            take(1)
                        ).subscribe((betConstraints: IBetTypeConstraint) => {
                            if (+total < +betConstraints.Min) {
                                this._betSlipErrorsService.setError(bet.id, BET_ERROR_CODES.MIN_NOT_MET);
                            } else {
                                this._betSlipErrorsService.setError(bet.id, BET_ERROR_CODES.MAX_EXCEEDED);
                            }
                        });
                    } else {
                        this._betSlipErrorsService.setError(bet.id, wagerResult.message, wagerResult.betShare);
                    }
                } else {
                    this._betSlipErrorsService.setError(bet.id, BET_ERROR_CODES.UNKNOWN_ERROR);
                }
            })
        );
    }

    public saveWager(bet: Bet): Observable<any> {
        return from(this._storageService.store(bet));
    }

    public removeWager(bet: Bet): Observable<any> {
        return from(this._storageService.destroy(bet));
    }

    public cancelWager(wager: IWager | ICancelWagerInfo | ITransaction, shouldThrowError = false): Promise<boolean> {
        const successMessage = 'success';
        let requestObs: Observable<string>;

        if ('betShareData' in wager && wager.betShareData.betShare) {
            requestObs = (
                wager.betShareData.betShareInfo && of(wager.betShareData.betShareInfo) ||
                this._betShareDataService.getBetShareDetails(wager.betShareData.betShareId)
            ).pipe(
                switchMap((betShareInfo) => this._betShareDataService.cancelBetShareWager(
                    betShareInfo.betShareId, betShareInfo.ticket
                )),
                tap((response) => {
                    if (shouldThrowError && response?.responseStatus?.toLowerCase() !== successMessage) {
                        throw new Error(response?.responseMessage || 'An unknown error has occurred.');
                    }
                }),
                map((response) => response?.responseStatus)
            );
        } else if ((('condWagerData' in wager && wager.condWagerData.conditionalWager) ||
            ('conditionalWagerData' in wager && wager.conditionalWagerData.conditionalWager))
        ) { // wager could be IWager or Transaction so handle either
            requestObs = this._wagerDataService.cancelConditionalWager(
                ('serialNumber' in wager && +wager.serialNumber) ||
                ('transactionId' in wager &&  +wager.transactionId)
            ).pipe(
                tap((response) => {
                    if (shouldThrowError && response?.toLowerCase() !== successMessage) {
                        throw new Error(response || 'An unknown error has occurred.');
                    }
                })
            );
        } else { // wager could be IWager, ICancelWagerRequest, or ITransaction
            requestObs = this._wagerDataService.cancelWager({
                brisCode: wager.brisCode || ('availableTrackCode' in wager && wager.availableTrackCode) || 'UNK',
                trackType: TrackService.getTrackTypeNum(<enumTrackType>wager.trackType),
                serialNumber: 'serialNumber' in wager ? wager.serialNumber : wager.transactionId,
                status: wager.status,
                // IWager stores base amount in "amount" and the total in "wagerAmount"
                // ICancelWagerRequest however expects the base amount in "wagerAmount"
                wagerAmount: wager['amount'] || wager['wagerAmount']
            }).pipe(
                tap((response) => {
                    if (shouldThrowError && response?.toLowerCase() !== successMessage) {
                        throw new Error(response || 'An unknown error has occurred.');
                    }
                })
            );
        }

        return requestObs.pipe(
            map(response => response && response.toLowerCase() === successMessage),
            tap(success => {
                if (success) {
                    this.logCancelWagerEvent(wager);
                    this._myBetsService.refreshMyBetsCache(this._isTournamentSelected, false);
                    this._completedBetsService.refreshTodaysCompletedBetsCache();
                }
            })
        ).toPromise();
    }

    public logWagerEvent(eventType: UserEventEnum, bets: IBet[] | IBetResult[], accountBalance?: number) {
        const trimmedBets: SharedBetProperties[] = bets as SharedBetProperties[];

        // Convert bets to user event objects (mostly 1-1 mapping)
        const eventBets = trimmedBets.map((bet) => {
            const formattedWager: any = {
                'betAmount': bet.cost || 0,
                'betId': bet.wagerId,
                'poolType': bet.betType,
                'trackName': bet.track,
                'raceNumber': bet.race,
                'horseNumber': bet.runList
            };

            if (eventType === UserEventEnum.BET_COMPLETE || eventType === UserEventEnum.BET_FAILED) {
                formattedWager.success = bet.success;
            }

            if (bet.conditional) {
                formattedWager.odds = bet.conditionalOdds;
                formattedWager.minutesToPost = bet.conditionalMtp;
                formattedWager.conditional = bet.conditional;
            }

            if (bet.betShare) {
                // anybody going through this flow will be a captain
                formattedWager.captainOfBetShare = true;
                formattedWager.numberOfShares = bet.shares.totalShares;
                formattedWager.numberOfSharesPurchased = bet.shares.reserveShares;
                formattedWager.betShare = bet.betShare;
            }

            return formattedWager;
        });

        const userInterface = this.getWagerEventInterface(this._route);
        const logBets = (userEvent, betsArray) => {
            if (betsArray.length > 0) {
                const eventData = (accountBalance !== null && accountBalance !== undefined) ? { bets: betsArray, interface: userInterface, accountBalance} : { bets: betsArray, interface: userInterface};
                this._eventTrackingService.logUserEvent(userEvent, eventData);
            }
        }
        if (eventType === UserEventEnum.BET_COMPLETE || eventType === UserEventEnum.BET_FAILED) {
            const wagerEvents = this._partitionSubmittedWagers(eventBets);
            wagerEvents.forEach(eventInfo => logBets(eventInfo.event, eventInfo.bets));
        } else {
            logBets(eventType, eventBets);
        }
    }

    public logCancelWagerEvent(wager: IWager | ICancelWagerInfo | ITransaction) {
        const cancelEventInfo = {
            trackName: wager['eventCode'] || wager['trackId'],
            raceNumber: wager['raceNum'],
            betAmount: wager['amount'] || wager['wagerAmount'],
            betId: wager['serialNumber'] || wager['transactionId']
        };
        this._eventTrackingService.logUserEvent(UserEventEnum.BET_CANCEL, cancelEventInfo);
    }

    private _partitionSubmittedWagers(array: SharedBetProperties[]): {event: UserEventEnum, bets: SharedBetProperties[]}[] {
        const pass = [], fail = [], conditional = [], betshare = [];
        array.forEach((bet) => {
            let arr;
            if (bet.success) {
                if (bet.conditional) {
                    arr = conditional;
                } else if (bet.betShare) {
                    arr = betshare;
                } else {
                    arr = pass;
                }
           } else {
               arr = fail;
           }
            ['success', 'conditional', 'betShare'].forEach(key => delete bet[key]);
            arr.push(bet);
        });
        return [
            {event: UserEventEnum.BET_COMPLETE, bets: pass},
            {event: UserEventEnum.BET_FAILED, bets: fail},
            {event: UserEventEnum.CONDITIONAL_BET, bets: conditional},
            {event: UserEventEnum.BET_SHARE, bets: betshare}
        ];
    }

    private getWagerEventInterface(route: ActivatedRoute): string {
        const routeParams = CduxRouteUtil.extractParams(route);
        let myInterface;
        if (routeParams && routeParams.section) {
            if (!!routeParams.view && routeParams.view.toLowerCase() === WageringViewEnum.VIDEO.toLowerCase()) {
                myInterface = 'tv' + routeParams.section;
            } else {
                // classic is only tab that isn't 1-to-1, route param is different
                myInterface =  routeParams.section.toLowerCase() === 'program' ?  'classic' : routeParams.section;
            }
        } else if (this._router.url?.toLowerCase().includes(WageringViewEnum.BETPAD)) {
            myInterface = WageringViewEnum.BETPAD;
        } else if (this._router.url?.toLowerCase().includes(WageringViewEnum.EXPRESS)) {
            myInterface = WageringViewEnum.EXPRESS;
        } else if (this._router.url?.toLowerCase().includes(WageringViewEnum.CLASSIC)) {
            myInterface = WageringViewEnum.CLASSIC;
        } else {
            myInterface = routeParams && routeParams.view || WageringViewEnum.DEFAULT;
        }
        return myInterface;
    }

    /**
     * We handle wagers placed after JWT tokens have expired by displaying an
     * error message, logging them out, and routing users to login.
     */
    public handleExpiredJwtError() {
        this._sessionService.addLogoutTask(() => new Promise<void>((resolve) => {
            this._toastService.cduxWarning(this._translateService.translate(BET_ERROR_CODES.EXPIRED_JWT, 'wager-errors'));
            resolve();
        }), false);
        this._sessionService.logout().pipe(take(1)).subscribe(() => {
            this._router.navigate(['/login']);
        });
    }

    /**
     * find the scratched horses and return the bet
     */
    public removeScratchedHorses(bet: Bet):  Observable<Bet> {
        const multipleRace = bet.poolType.MultipleRace;
        const raceEntriesObsArray: Observable<ProgramEntry[]>[] = [];
        if (multipleRace) {
            bet.runners.forEach((race, i) => {
                const raceEntriesObs = this._todaysRacesService.getTodaysRaceEntries(bet.track.BrisCode, bet.track.TrackType, (bet.race.race + i)).pipe(take(1));
                raceEntriesObsArray.push(raceEntriesObs);
            });
        } else {
            raceEntriesObsArray.push(this._todaysRacesService.getTodaysRaceEntries(bet.track.BrisCode, bet.track.TrackType, bet.race.race).pipe(take(1)));
        }
        return forkJoin(raceEntriesObsArray).pipe(
            map((programEntriesArray: ProgramEntry[][]) => {
                const currentBet = Bet.fromBetlike(bet)
                    bet.runners.forEach((selectedRunnerLeg, runnerLegIndex) => {
                        selectedRunnerLeg.forEach((selectedEntry) => {
                            // need to remove the runners if multipleRace then we need to compare with program entry leg comparing the one program entry call by current race
                            const entryScratchedAmongSelected =  programEntriesArray[multipleRace ? runnerLegIndex : 0].filter(entry => entry.ProgramNumber === selectedEntry.ProgramNumber && entry.Scratched);
                            if (entryScratchedAmongSelected.length > 0) {
                                const index = currentBet.runners[runnerLegIndex].findIndex(e => e.ProgramNumber === selectedEntry.ProgramNumber);
                                currentBet.runners[runnerLegIndex].splice(index, 1);
                            }
                        });
                    });
                return currentBet;
                }),
            catchError(() => of(bet)));
    }
}
