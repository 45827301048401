import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { combineLatest, interval, of, BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, first, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumFeatureToggle,
    enumRaceStatus,
    EventClickType,
    FeatureToggleDataService,
    PoolType,
    TrackService,
    WagerState,
    JwtSessionService,
} from '@cdux/ng-common';
import { IWager, WagerDisplayStatus, ZendeskChatService } from '@cdux/ng-fragments';
import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { BetsCommonService } from 'app/shared/bets/services/bets-common.service';
import { CompletedBetsBusinessService } from 'app/shared/bets/services/completed-bets.business.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { BetsContainerComponent, BetsViewEnum } from 'app/shared/bets/components/bets-container/bets-container.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { takeWhileInclusive } from 'app/shared/common/operators';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-mybets-by-track',
    templateUrl: './my-bets-by-track.component.html',
    styleUrls: ['./my-bets-by-track.component.scss']
})
export class MyBetsByTrackComponent implements OnInit, OnDestroy {
    public enumRaceStatus = enumRaceStatus;
    public EventClickType = EventClickType;
    private _isTournamentSelected = this._tournamentSessionService.isTournamentSelected();

    @Input()
    public set raceStatus(raceStatus: enumRaceStatus) {
        this._raceStatusSubject.next(this._raceStatus = raceStatus);
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }
    private _raceStatusSubject = new BehaviorSubject<enumRaceStatus>(null);
    private _raceStatus: enumRaceStatus;

    public get affiliateId(): string {
        return this.environment.affiliateId.toString();
    }

    @Input()
    public set wagerState(wagerState: WagerState) {
        this._wagerStateSubject.next(this._wagerState = wagerState);
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }
    private _wagerStateSubject = new BehaviorSubject<WagerState>(null);
    private _wagerState: WagerState;

    public wagerDisplayStatus = WagerDisplayStatus;

    public highlightedBet: string = '';
    public activeBet: IWager = null;
    public cancelingBet: IWager = null;
    public cancelError = false;
    private _cancelInProgress = false;

    public bets: IWager[] = [];
    public isLoggedIn = false;
    public isLoadingBets = false;
    public zendeskEnabled: boolean;
    public phoneSupportEnabled: boolean;

    private _updateBetStatusSubject = new Subject<void>();
    private _destroySubject = new Subject<void>();

    private _splitBetButtonFT: boolean = false;
    private _completedBetsInterval: number;


    constructor(
        private configService: ConfigurationDataService,
        private environment: ENVIRONMENT,
        private betsService: BetsBusinessService,
        private betsCommonService: BetsCommonService,
        private completedBetsService: CompletedBetsBusinessService,
        private changeDetector: ChangeDetectorRef,
        private eventTrackingService: EventTrackingService,
        private featureToggleService: FeatureToggleDataService,
        private myBetsService: MyBetsBusinessService,
        private sessionService: JwtSessionService,
        private sidebarService: SidebarService,
        private todaysRacesService: TodaysRacesBusinessService,
        private zendeskChatService: ZendeskChatService,
        private eventService: EventTrackingService,
        private _tournamentSessionService: TournamentsSessionService
    ) { }

    public ngOnInit() {
        const  pollingKey = 'poll_my_bets_by_track';

        // this init is required for the copyBet => copyBetAndEditBet functionality
        this.betsCommonService.initializeCommonService().pipe(first()).subscribe();

        combineLatest([
            this.sessionService.onAuthenticationChange.pipe(
                startWith(this.sessionService.isLoggedIn()),
                distinctUntilChanged()
            ),
            this._wagerStateSubject.pipe(
                distinctUntilChanged((a, b) =>
                    (!a && !b) || (!!a && !!b) && // check for to/from null
                    TrackService.isSameTrack(a.basicTrack, b.basicTrack) &&
                    +a.basicTrack.RaceNum === +b.basicTrack.RaceNum
                )
            ),
            this._raceStatusSubject.pipe(distinctUntilChanged()),
            this.configService.getConfiguration(enumConfigurationStacks.TUX, pollingKey).pipe(take(1))
        ]).pipe(
            debounceTime(200), // wait for all params
            switchMap(([isLoggedIn, wagerState, raceStatus, config]) => {
                this.activeBet = null;
                this.cancelingBet = null;
                this.cancelError = false;
                this.isLoggedIn = isLoggedIn;
                this._completedBetsInterval =  !!config[pollingKey] ? (+config[pollingKey]  *  1000) : 30000;

                // use empty array if user is not logged in or wager state or race status is invalid
                if (!isLoggedIn || !wagerState || !wagerState.basicTrack || !raceStatus) {
                    return of([]);
                }

                this.isLoadingBets = true;
                this.changeDetector.markForCheck();
                return this._getCombinedBetsObservable(wagerState, raceStatus).pipe(
                    tap(() => this.isLoadingBets = false),
                    startWith([]), catchError(() => [])
                );
            }),
            takeUntil(this._destroySubject)
        ).subscribe((bets: IWager[]) => {
            // if there's a new wager on the list
            if (bets.length > this.bets.length && this.bets.length > 0) {
                // find the the new wager by comparing to the old wagers by serial number, store the serial number
                this.highlightedBet = bets.filter(({ serialNumber: serial1 }) => !this.bets.some(({ serialNumber: serial2 }) => serial1 === serial2))[0].serialNumber;
                // clear out the stored serial number to prevent reflows from triggering the animation again
                setTimeout(() => { this.highlightedBet = ''; this.changeDetector.markForCheck() }, 10000);
            }
            if (this._isTournamentSelected) {
                bets = bets.filter((wager) => wager.wagerTypeId === "TOURNAMENT");
            }
            // if in a non-tournament account, filter out any tournament wagers
            if (!this._isTournamentSelected) {
                bets = bets.filter((wager) => !wager.wagerTypeId || wager.wagerTypeId !== "TOURNAMENT");
            }
            this.bets = bets;
            this.changeDetector.markForCheck();
            this._updateBetStatusSubject.next();
        });

        combineLatest(
            this.todaysRacesService.getTodaysTracks(true, true),
            this._updateBetStatusSubject
        ).pipe(
            map(([todaysTracks]) => todaysTracks), // discard update triggers
            takeUntil(this._destroySubject)
        ).subscribe(todaysTracks => this.bets.forEach(bet => {
            const todaysTrack = todaysTracks.find(tt => TrackService.isSameTrack(tt.ITrackBasic, {
                BrisCode: bet.brisCode, TrackType: bet.trackType
            }));
            if (todaysTrack) {
                const race = todaysTrack.races.find(tr => +tr.raceNumber === +bet.raceNum);
                if (race) { bet.raceStatus = race.status; }
            }
            this.changeDetector.markForCheck();
        }));

        this._splitBetButtonFT = this.featureToggleService.isFeatureToggleOn('SPLIT_BET_BUTTON');
        this.zendeskEnabled = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.ZENDESK_CHAT);
        this.phoneSupportEnabled = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
    }

    private _getCombinedBetsObservable(wagerState: WagerState, raceStatus: enumRaceStatus): Observable<IWager[]> {
        const wagerStateFilter = map((bets: IWager[]) =>
            !bets ? [] : bets.filter(bet => {
                // omit wagers which do not match the specified track
                if (!TrackService.isSameTrack(wagerState.basicTrack, {
                    BrisCode: bet.brisCode, TrackType: bet.trackType
                })) {
                    return false;
                } else {
                    // omit wagers which do not involve the specified race
                    const poolType = PoolType.getPoolTypeByCode(bet.poolType);
                    const legCount = poolType && poolType.raceLegs || 1;
                    return (
                        +wagerState.basicTrack.RaceNum >= +bet.raceNum &&
                        +wagerState.basicTrack.RaceNum < +bet.raceNum + legCount
                    );
                }
            })
        );

        const myBetsObs = this.myBetsService.getMyBetsCache().pipe(wagerStateFilter);

        // no need to check completed bets if race is not official
        if (!this.isRaceOfficial(raceStatus)) { return myBetsObs; }

        const completedBetsObs = this.completedBetsService.getTodaysCompletedBetsCache(this._completedBetsInterval).pipe(wagerStateFilter);

        return combineLatest([
            myBetsObs,
            this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.MY_BETS_POLLING)
                ? interval(this._completedBetsInterval).pipe(startWith(0), switchMap(() => completedBetsObs))
                : completedBetsObs.pipe(first())
        ]).pipe(
            map(([myBets, completedBets]) => [ // remove MyBets entries which exist in CompletedBets
                myBets.filter(b1 => !completedBets.some(b2 => b1.serialNumber === b2.serialNumber)),
                completedBets.filter(bet => // remove Failed and Canceled entries from CompletedBets
                    bet.status !== WagerDisplayStatus.CANCELLED &&
                    bet.status !== WagerDisplayStatus.FAILED
                )
            ]),
            // keep polling while some bets are still active
            // only poll for final races of multi-leg wagers
            takeWhileInclusive(([myBets]) => myBets.some(bet => {
                const poolType = PoolType.getPoolTypeByCode(bet.poolType);
                return +wagerState.basicTrack.RaceNum === +bet.raceNum + poolType.raceLegs - 1;
            }), true),
            // merge MyBets with CompletedBets, they should both be unique
            map(([myBets, completedBets]) => myBets.concat(completedBets))
        );
    }

    public ngOnDestroy() {
        this._destroySubject.next();
        this._destroySubject.complete();
    }

    public launchZeMessenger() {
        if (this.zendeskEnabled && this.zendeskChatService.isLoaded()) {
            this.logEvent(EventClickType.SUPPORT_CHAT_REQUEST);
            this.zendeskChatService.setMessengerVisibility(true);
            this.zendeskChatService.openZeMessenger();
        }
    }

    public logEvent(event: EventClickType) {
        this.eventService.logClickEvent(event);
    }

    public isRaceOfficial(raceStatus = this.raceStatus): boolean {
        return [
            enumRaceStatus.OFFICIAL,
            enumRaceStatus.CLOSED,
            enumRaceStatus.CANCELED
        ].indexOf(raceStatus) > -1;
    }

    public isRaceWagerable(raceStatus = this.raceStatus): boolean {
        return TrackService.isWagerableRace(raceStatus);
    }

    public canCopyBet(bet: IWager) {
        return this.isRaceWagerable(<enumRaceStatus> bet.raceStatus) &&
            // unable to copy bet share
            !bet.betShareData?.betShare;
    }

    public canCancelBet(bet: IWager) {
        return this.isRaceWagerable(<enumRaceStatus> bet.raceStatus) &&
            // only captain can cancel bet share
            !bet.betShareData?.betShare === !bet.betShareData?.betShareCaptain;
    }

    public toggleCopyCancel(bet: IWager) {
        if (this.activeBet === bet) {
            this.activeBet = null;
            this.eventTrackingService.logClickEvent(EventClickType.MY_BETS_COLLAPSE);
        } else {
            this.activeBet = bet;
            this.cancelingBet = null;
            this.eventTrackingService.logClickEvent(EventClickType.MY_BETS_EXPAND);
        }
    }

    public toggleCancelConfirm(bet: IWager) {
        if (this._cancelInProgress) { return; }
        this.cancelError = false;

        if (this.cancelingBet === bet) {
            this.cancelingBet = null;
        } else {
            this.cancelingBet = bet;
            this.eventTrackingService.logClickEvent(EventClickType.MY_BETS_DELETE);
        }
    }

    public copyBet(bet: IWager) {
        // TODO: find a way to detect an error here
        this.betsCommonService.copyAndEditWager(bet);
        this.eventTrackingService.logClickEvent(EventClickType.MY_BETS_COPY);
    }

    public cancelBet(bet: IWager) {
        if (this._cancelInProgress) { return; }
        this.cancelError = false;
        this._cancelInProgress = true;

        this.betsService.cancelWager(bet).catch(() =>
            false // TODO: make errors meaningful?
        ).then(isCanceled => {
            if (isCanceled) {
                const index = this.bets.indexOf(bet);
                if (index > -1) { this.bets.splice(index, 1); }
            }

            this.cancelError = !isCanceled;
            this._cancelInProgress = false;
            this.changeDetector.markForCheck();
        });
    }

    public viewAllMyBets() {
        if (this._splitBetButtonFT) {
            this.sidebarService.loadComponent(
                TodaysBetsContainerComponent.getSidebarComponent()
            );
        } else {
            this.sidebarService.loadComponent(
                BetsContainerComponent.getSidebarComponent({ currentBetsView: BetsViewEnum.ACTIVE_BETS }),
                BetsContainerComponent.getHeaderComponent(),
                { clearHistory: true }
            );
        }
    }

    public trackBySerialNumber(bet: IWager): string {
        return bet.serialNumber;
    }
}
