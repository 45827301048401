import { Injectable } from '@angular/core';
import {
    enumFeatureToggle,
    EzmoneyDataService,
    FeatureToggleDataService,
    JwtSessionService,
    PlaidDataService,
    IFundingOption,
    FUND_ID
} from '@cdux/ng-common';
import { Observable, Observer } from 'rxjs';
import { flatMap } from 'rxjs/operators';


import { IFundingService, IFundingAccountDetails } from '../interfaces/funding.interfaces';
import { Router } from '@angular/router';

@Injectable()
export class FundingEzmoneyService implements IFundingService {

  constructor(
    private _ezmoneyDataService: EzmoneyDataService,
    private _plaidDataService: PlaidDataService,
    private _sessionService: JwtSessionService,
    private _router: Router,
    private _featureToggleService: FeatureToggleDataService
  ) {
    // Empty
  }

    private validateLogin() {
        if (!this._sessionService.isLoggedIn()) {
            this._router.navigateByUrl('/login');
        }
    }


    /**
   * Triggers a deposit for the specified Funding Method if it exists or sets it up using the supplied
   * Funding Account Details
   * @param amount - Amount to be deposited
   * @param method - Funding Method to be used for deposit
   * @param accountDetails - Account Details for New Funding Method Setup
   */
  public deposit(amount: string | number, account?: IFundingAccountDetails): Observable<any> {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
    if (account && account !== null) {
      return this._setupFundingMethod(account).pipe(
        flatMap((response) => {
          if (response.status !== 'success') {
            return new Observable((observer: Observer<any>) => {
              observer.next(response);
              observer.complete();
            });
          }
          return this._deposit(<number> amount);
        })
      );
    } else {
      return this._deposit(amount);
    }
  }

  public getAccountInfo(): Observable<any> {
    return this._ezmoneyDataService.requestAccountInfo();
  }

  /**
   * Withdraws the Specified Amount From the Linked EZMoney Account
   * @param amount - Amount to be Withdrawn
   */
  public withdraw(amount: number, account?: IFundingAccountDetails): Observable<any> {
    this.validateLogin();
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
      if (account && account !== null) {
          return this._setupFundingMethod(account).pipe(
              flatMap((response) => {
                  if (response.status !== 'success') {
                      return new Observable((observer: Observer<any>) => {
                          observer.next(response);
                          observer.complete();
                      });
                  }
                  return this._ezmoneyDataService.withdraw(amount);
              })
          );
      } else {
          return this._ezmoneyDataService.withdraw(amount);
      }
  }

  public canInitiatePlaid(methodObj: IFundingOption): boolean {
    const isVerified = methodObj.verified;
    const hasEzMoneyAccountInfo = !!methodObj.accountInfo;
    const ezMoneyFundId = methodObj.fundId;

    if (!isVerified && hasEzMoneyAccountInfo && ezMoneyFundId === FUND_ID.EZMONEY_W) {
      return false
    }
    return true
  }

  /**
   * Requests New Account Creation for provided Funding Method using the Account Details
   * @param method - Method being setup
   * @param account - Account being used for method
   */
  private _setupFundingMethod(account: IFundingAccountDetails): Observable<any> {
    this.validateLogin();
      /* todo: Due to tr-funding changes, the POST call to persistaccount doesn't work in the lower environments
       * If tr-funding changes go to higher environments or are backed out. The GET call and toggle should be removed and the POST call
       * fixed to work with tr-funding changes. The GET call reveals PII banking info as a query param.
       * Do not use the GET for production code, use the POST instead. It is being maintained here so that EzMoney works in lower and higher environments.
       */

    if(!!account.plaidSession) {
        return this._plaidDataService.exchangePublicToken(account.plaidSession);
    } else {
        const setupAsPost = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.EZM_SETUP_POST);
        return setupAsPost ? this._ezmoneyDataService.persistAccount(account.accountNumber, account.routingNumber, account.accountType)
            : this._ezmoneyDataService.setup(account.accountNumber, account.routingNumber, account.accountType);
    }
  }

  /**
   * Requests a Deposit for the provided Amount using the specified Funding Method
   * @param amount - Amount to be deposited
   * @param method - Method to be used for deposit
   */
  private _deposit(amount: number): Observable<any> {
    this.validateLogin();
    return this._ezmoneyDataService.deposit(amount);
  }
}
