import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router"
import { TournamentsSessionService } from "app/shared/tournaments-session/services/touranments-session.service"
import { ItournamentsNotAvailableModalConfig, TournamentsNotAvailableModalService } from "app/shared/tournaments-session/services/tournaments-not-available-modal.service";

const depositNotAvailableModalConfig: ItournamentsNotAvailableModalConfig  =
{
    destinationPath: '/todays-races/time',
    title: 'Deposit Unavailable',
    notice: 'You are signed into your Tournament account and cannot deposit funds into your bankroll. You must switch to your primary account to deposit funds to your primary account balance.'
}

export const TournamentGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {

    if (!inject(TournamentsSessionService).isTournamentSelected()) {
        return true;
    }
 
    if (route.routeConfig.path === 'funding/:operation/:method') { 
        inject(TournamentsNotAvailableModalService).openModal(depositNotAvailableModalConfig)
        return false;
    }
    return inject(Router).createUrlTree(['tournaments/unavailable']);
};

export const TournamentGuardChild: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
    return inject(TournamentsSessionService).isTournamentSelected() ? inject(Router).createUrlTree(['tournaments/unavailable']) : true;
};