import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { CduxRequestError } from '@cdux/ng-core';
import {
    AccountBalanceDataService,
    AccountInfoDataService,
    BetAmountsService,
    CARD_TYPES,
    DetectionService,
    EventsService,
    FeatureToggleDataService,
    FUND_ID,
    FUNDING_OPERATIONS,
    IAccountBalance,
    IAccountFundingInfo,
    IFundingOption,
    IWithdrawBalance,
    JwtSessionService,
    OffersDataService,
    PlayerGroupsService,
    UserEventEnum,
} from '@cdux/ng-common';

import { BetPadView } from 'app/shared/program/enums/bet-pad-view.enum';
import { BetsContainerComponent } from 'app/shared/bets/components/bets-container/bets-container.component';
import { EToteStatus, ToteStatusService } from 'app/shared/tote-status/tote-status.service';
import { EventKeys } from 'app/shared/common/events/event-keys';
import { FEATURE_TOGGLE_TOUR } from 'app/shared/common/constants';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { TourService } from 'app/shared/tour/services';

import {
    IEnabledMethods,
    IFundingEvent,
} from '../interfaces/funding.interfaces';
import { enumDepositOptions, enumWithdrawOptions } from '../../components/methods/abstract-method.component';
import { enumFullpageMethodToggles } from '../enums/fullpage-method-toggles.enum';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { enumFundingDisplayStyle } from '../enums/funding-display-style.enum';

enum SuggestionType {
    GREATER,
    LESS
}

@Injectable()
export class FundingService {

    // This will be returned when there is no previous deposit amount
    public static readonly defaultDepositAmounts = [200, 100, 50, 20, 10];

    // Path to return user to post deposit
    public postDepositRedirectURL: string;

    // List of funding methods that are enabled, populated from feature toggles
    private ENABLED_FUNDING_METHODS: IEnabledMethods = {
        deposit: [],
        withdraw: []
    };

    private readonly FEATURE_FLAG_OFFER_OPTOUT_WITHDRAW = 'F1653';
    // Current Active Funding Method
    public activeFundingMethod: FUND_ID;
    public activeWithdrawalMethod: FUND_ID;

    // Last Funding Amount
    public lastFundingAmount: number;

    public isDepositCarriedFromRaces: boolean;

    // Collection of Funding Options
    public fundingOptions: IFundingOption[] = [];

    public onFundingSuccess: EventEmitter<any> = new EventEmitter();

    public onFundingEvent: EventEmitter<IFundingEvent> = new EventEmitter();

    public balanceUpdated = new Subject<number>();
    public accountInfoUpdated = new Subject<boolean>();
    public onReplaceFundingAccount = new Subject<undefined>();

    set ezBankErrorCode(errorCode: any) {
        this._ezBankErrorCode = errorCode;
    }

    get ezBankErrorCode(): any {
        return this._ezBankErrorCode;
    }

    get accountBalance(): number {
        return this._accountBalance;
    }

    get withdrawBalance(): IWithdrawBalance {
        return this._withdrawBalance;
    }

    private _accountBalance = 0;
    private _withdrawBalance: IWithdrawBalance | null;
    private _ezBankErrorCode;

    constructor(
        private _accountBalanceService: AccountBalanceDataService,
        private _accountInfoService: AccountInfoDataService,
        private _betAmountsService: BetAmountsService,
        private _detectionService: DetectionService,
        private _eventsService: EventsService,
        private _featureToggleService: FeatureToggleDataService,
        private _offersService: OffersDataService,
        private _playerGroupService: PlayerGroupsService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _sidebarService: SidebarService,
        private _toteStatusService: ToteStatusService,
        private _tourService: TourService,
        private _eventTrackingService: EventTrackingService,
    ) {
        this.setEnabledFundingMethods();

        // TODO: Refactor this out. We should use the pouchdb data store to store the account balance under a key that can be cleared at logout.
        this._eventsService.on(EventKeys.AUTH_STATUS_CHANGED).subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.updateAccountBalance();
            } else {
                this._accountBalance = 0;
                this._withdrawBalance = null;
            }
        });
    }

    public getWithdrawalOptOutList(): Observable<string[]> {
        return this._featureToggleService.isFeatureToggleOn(this.FEATURE_FLAG_OFFER_OPTOUT_WITHDRAW)
            ? this._offersService.getWithdrawalOptOutList() : of([]);
    }

    public setEnabledFundingMethods(displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR) {
        this.ENABLED_FUNDING_METHODS = {
            deposit: [],
            withdraw: []
        };
        for (const key in FUND_ID) {
            // Looping over an enum will return a list of its keys AND values.
            // We only want to process the keys, so we skip any numbers, which are the values
            if (isNaN(parseInt(key, 10)) && FUND_ID.hasOwnProperty(key)) {
                const fundCode: string = key.toString();
                let fullpageEnabled = true;
                // Check the feature toggle for this funding method and see if it's enabled.
                // If this is fullpage, we need to check those toggles as well. If not fullpage
                // we'll set the variable to true so that only the overall toggle is checked.
                if ( displayStyle === enumFundingDisplayStyle.FULL_PAGE) {
                    fullpageEnabled = this._featureToggleService.isFeatureToggleOn(enumFullpageMethodToggles[fundCode]);
                }

                const enabled = this._featureToggleService.isFeatureToggleOn(fundCode) && fullpageEnabled;
                if (enabled) {
                    if (fundCode.indexOf('_W') >= 0) {
                        this.ENABLED_FUNDING_METHODS.withdraw.push(FUND_ID[fundCode]);
                    } else {
                        this.ENABLED_FUNDING_METHODS.deposit.push(FUND_ID[fundCode]);
                    }
                }
            }
        }
    }

    /**
     * Closes out a successful Funding Request
     */
    public closeFundingMethod(bet: string, offerId: number, amountDeposit: number, updatedBalance: number, isBetPad: boolean, operation: FUNDING_OPERATIONS, operationMethod: enumWithdrawOptions | enumDepositOptions, isBetShare: boolean = false) {
        if (!isBetShare && !isBetPad) {
            this._sidebarService.close(true);
        }

        if (typeof updatedBalance === 'undefined') {
            // We've not been able to retrieve the new account balance with the transaction.
            // I do it here with an explicit call, which internally updates the balance on the service.
            this.updateAccountBalance().subscribe((balance: IAccountBalance) => {
                this._completeTransaction(bet, offerId, amountDeposit, balance.Balance, isBetPad, operation, operationMethod, isBetShare);
            });
        } else {
            // We've received an updated balance with the transaction. Update the service's info now and wrap up.
            this._accountBalance = updatedBalance;
            this.balanceUpdated.next(updatedBalance);
            this._completeTransaction(bet, offerId, amountDeposit, updatedBalance, isBetPad, operation, operationMethod, isBetShare);
        }
    }

    /**
     * The method will return the AccountBalance for a given deposit method.
     */
    public updateAccountBalance(): Observable<IAccountBalance> {
        this._validateLogin();
        return this._accountBalanceService.requestAccountBalance().pipe(
            tap((balanceData: IAccountBalance) => {
                this._accountBalance = balanceData.Balance;
                this.balanceUpdated.next(this._accountBalance);

                // if we didn't catch an error from requestAccountBalance, assume tote is up
                this._toteStatusService.updateToteStatus(EToteStatus.TOTE_STATUS_UP);
            }),
            catchError((err: CduxRequestError, caught) => {
                this._accountBalance = 0;
                if ('message' in err && ToteStatusService.RE_TOTE_DOWN.test(err.message)) {
                    this._toteStatusService.updateToteStatus(EToteStatus.TOTE_STATUS_DOWN);
                } else {
                    this._toteStatusService.updateToteStatus(EToteStatus.TOTE_STATUS_UNKNOWN);
                }

                throw err;
            }),
        );
    }

    public getWithdrawBalance(): Observable<IWithdrawBalance> {
        this._validateLogin();
        return this._accountBalanceService.requestWithdrawBalance().pipe(
            map((balanceData: IWithdrawBalance) => {
                this._withdrawBalance = balanceData;
                return this._withdrawBalance;
            })
        );
    }

    /**
     * Retrieves and Massages Data from EcoFlush Call
     * If you want the locally stored ecoflush data, use getFundingMethodData() (see below)
     */
    public requestFundingMethodData(type: FUNDING_OPERATIONS, enabledFundIds: FUND_ID[] = null, amexEnabled: boolean = false): Observable<IFundingOption[]> {
        this._validateLogin();

        // filter the list of enabled funding methods to be returned to only
        // include those that are passed in via enabledFundIds.
        enabledFundIds = this.ENABLED_FUNDING_METHODS[type].filter((fundId) => {
            // if enabledFundIds is null or empty, include this fundId
            // otherwise, include this fundId if it's in the enabledFundIds array.
            return (!enabledFundIds || enabledFundIds.length < 1 || enabledFundIds.indexOf(fundId) >= 0);
        });

        return this._accountInfoService.requestAccountInfo({
            enabledFundIDs: enabledFundIds,
            type: type
        }).pipe(
            map((fundingMethodData: IAccountFundingInfo) => {

                const lastMethod: FUND_ID = (type === FUNDING_OPERATIONS.WITHDRAW) ? fundingMethodData.lastWithdrawalMethod : fundingMethodData.lastFundingMethod;

                if (type === FUNDING_OPERATIONS.WITHDRAW) {
                    this.fundingOptions = this._sortWithdrawMethods(fundingMethodData.fundingOptions);
                } else {
                    this.fundingOptions = this._sortFundingMethods(lastMethod, fundingMethodData.fundingOptions, amexEnabled);
                }

                if (fundingMethodData.lastFundingMethod) {
                    if (enabledFundIds.includes(fundingMethodData.lastFundingMethod)) { // This fixing Offer or BetShare SG page keep loading issue when last deposit method is not in the funding option list
                        this.activeFundingMethod = fundingMethodData.lastFundingMethod;
                    }
                }

                if (fundingMethodData.lastWithdrawalMethod) {
                    this.activeWithdrawalMethod = fundingMethodData.lastWithdrawalMethod;
                }

                this.lastFundingAmount = fundingMethodData.lastFundingAmount;
                this.accountInfoUpdated.next(true); // Signal funding acct info update, especially last deposit amount

                return this.fundingOptions;
            })
        );
    }

    /**
     * Clear any funding method data currently stored.
     */
    public clearFundingMethods() {
        this.fundingOptions = null;
        this.lastFundingAmount = null;
        this.activeFundingMethod = null;
    }

    /**
     * Sorts the Funding Methods by Sort Order, Moving Last Used to the top
     * @param activeFundingMethod - Last Used Funding Method
     * @param fundingMethods - Collection of Funding Methods
     * @param amexEnabled - Validate if AMEX is enabled
     */
    private _sortFundingMethods(activeFundingMethod: FUND_ID, fundingMethods: IFundingOption[], amexEnabled: boolean = false) {
        const activeMethods: IFundingOption[] = [];
        const inactiveMethods: IFundingOption[] = [];

        fundingMethods.map((fundingMethod) => {
            if (fundingMethod.fundId === FUND_ID.CREDITCARD && !amexEnabled && fundingMethod.cardTypeID === CARD_TYPES.AMEX) {
                fundingMethod.accountInfo = null;
                fundingMethod.cardTypeID = null;
            }
            if (fundingMethod.fundId === activeFundingMethod) {
                activeMethods.push(fundingMethod);
            } else {
                inactiveMethods.push(fundingMethod);
            }
        });

        return [
            ...activeMethods.sort((a: IFundingOption, b: IFundingOption) => a.displayOrder - b.displayOrder),
            ...inactiveMethods.sort((a: IFundingOption, b: IFundingOption) => a.displayOrder - b.displayOrder)
        ];
    }

    private _sortWithdrawMethods(fundingOptions: IFundingOption[]) {
        const orderedWithdrawMethods: IFundingOption[] = [];

        fundingOptions.forEach((method) => {
            if (method.fundId === FUND_ID.EZMONEY_W) {
                method.displayOrder = 0;
                orderedWithdrawMethods.push(method);
            } else if (method.fundId === FUND_ID.PAYPAL_W) {
                method.displayOrder = 1;
                orderedWithdrawMethods.push(method);
            } else if (method.fundId === FUND_ID.CHECK_W) {
                method.displayOrder = 2;
                orderedWithdrawMethods.push(method);
            } else {
                method.displayOrder = 3;
                orderedWithdrawMethods.push(method);
            }
        });

        return orderedWithdrawMethods.sort((a: IFundingOption, b: IFundingOption) => a.displayOrder - b.displayOrder);
    }

    /**
     * Build Suggestion List Based on Provided Amount
     * @param lastDeposit - Value to use to generate suggestions
     */
    public getSuggestions(lastDeposit: number, numberOfSuggestions: number = 4): Observable<number[]> {
        if (lastDeposit) {
            return this._betAmountsService.defaultBetAmounts()
            .pipe(
                take(1),
                map((betAmounts: string[]) => betAmounts.map((c) => parseInt(c, 10))),
                map(
                    (suggestionArray: number[]) => {
                        let suggestions: number[] = [];

                        const gtSuggestions = this._getSuggestionType(SuggestionType.GREATER, lastDeposit, suggestionArray);
                        const ltSuggestions = this._getSuggestionType(SuggestionType.LESS, lastDeposit, suggestionArray);
                        switch (gtSuggestions.length) {
                            case 0:
                                suggestions = ltSuggestions;
                                break;
                            case 1:
                            case 2:
                            case 3:
                                suggestions = gtSuggestions.concat(ltSuggestions.slice(0, numberOfSuggestions - gtSuggestions.length));
                                break;
                            default:
                                if (ltSuggestions.length) {
                                    suggestions = gtSuggestions.slice(0, numberOfSuggestions - 1).concat(ltSuggestions.slice(0, 1));
                                } else {
                                    suggestions = gtSuggestions.slice(0, numberOfSuggestions);
                                }
                        }

                        suggestions = suggestions.slice(0, numberOfSuggestions);
                        return suggestions.sort((a, b) => a - b);
                    }
                )
            );
        } else {
            return of(FundingService.defaultDepositAmounts.slice(0, numberOfSuggestions).reverse());
        }
    }

    /**
     * Wraps up a successful transaction.
     *
     * @param bet
     * @param offerId
     * @param amountDeposit
     * @param updatedBalance
     * @param isBetPad
     * @param isBetShare
     * @private
     */
    private _completeTransaction(bet: string, offerId: number, amountDeposit: number, updatedBalance: number, isBetPad: boolean, operation: FUNDING_OPERATIONS, operationMethod: enumWithdrawOptions | enumDepositOptions, isBetShare: boolean = false) {
        /**
         * Send marketing event for all Withdraw methods
         */
        // Check the response type is withdraw
        if (operation === FUNDING_OPERATIONS.WITHDRAW) {
            const withdraw_event = {
                'userBalance' : updatedBalance,
                'withdrawMethod': operationMethod,
                'withdrawAmount': amountDeposit,
                'timestamp': new Date().getTime(),
                'osVersion': this._eventTrackingService.getOsVersion()
            };
            this._eventTrackingService.logUserEvent(UserEventEnum.WITHDRAW, withdraw_event);
        }

        /**
         * Send event for Deposit methods
         */
        // Check the response type is deposit
        if (operation === FUNDING_OPERATIONS.DEPOSIT && !!operationMethod) {
            const firstTimeDeposit = this.isFirstTimeDeposit();
            const deposit_event = {
                'eventName': firstTimeDeposit ? UserEventEnum.DEPOSIT_FIRST_TIME : UserEventEnum.DEPOSIT_REGULAR,
                'firstTimeDeposit': firstTimeDeposit,
                'depositAmount': amountDeposit,
                'depositMethod': operationMethod,
                'userBalance': updatedBalance,
                'osVersion': this._eventTrackingService.getOsVersion(),
                'action': 'complete',
                'failReason': null,
                'status': 'success'
            };
            this._eventTrackingService.logUserEvent(UserEventEnum.DEPOSIT, deposit_event);
        }

        this.onFundingSuccess.emit({
            balance: updatedBalance,
            bet,
            amountDeposit,
            offerId
        });

        if (!!bet && !isBetShare && !isBetPad) {
            this._sidebarService.loadComponent(BetsContainerComponent.getSidebarComponent({ returningBetId: bet }));
        } else if (!!bet && isBetPad) {
            this._router.navigate(['/', BetPadView.ROOT_PATH, BetPadView.MY_BETS], { state: { 'returningBetId': bet }} );
        } else if (isBetPad && this.isDepositCarriedFromRaces) {
            this._router.navigate(['/', BetPadView.ROOT_PATH, BetPadView.RACES]);
        }
        this.isDepositCarriedFromRaces = false;
        // Call the tour on successful deposits
        if (this._featureToggleService.isFeatureToggleOn(FEATURE_TOGGLE_TOUR) && !isBetShare && !isBetPad && !this._detectionService.isPhone()) {
            this._tourService.showTour();
        }
    }


    /**
     * Returns List of Suggestions Per Type
     * @param type - Type to retrieve, GREATER or LESS
     * @param lastDeposit - Number to be tested against for Type comparison
     */
    private _getSuggestionType(type: SuggestionType, lastDeposit: number, suggestionArray: number[]): number[] {
        return suggestionArray.filter((v) => {
            if (type === SuggestionType.GREATER) {
                return v > lastDeposit;
            }
            return v <= lastDeposit;
        }).sort((a, b) => {
            if (type === SuggestionType.GREATER) {
                return a - b;
            }
            return b - a;
        });
    }

    // Validates the current session
    private _validateLogin() {
        if (!this._sessionService.isLoggedIn()) {
            this._router.navigateByUrl('/login');
        }
    }

    // Returns true if first time deposit
    public isFirstTimeDeposit(): boolean {
        return !this._playerGroupService.inHasFundedGroup() && (this.lastFundingAmount === 0);
    }

}
