import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    AccountDataService,
    IAccountHistoryBetTypes,
    IAccountHistorySummaryDetails,
    IAccountHistoryTracks,
    IAccountTransaction,
    IAccountTransactionPaged,
    IAvailableResultsRaceDataResponse,
    CduxDateUtil,
    RaceDateService,
    IRaceIdentifier,
    ResultsDataService
} from '@cdux/ng-common';
import {
    ITransaction,
    ITransactionRange,
    AccountHistoryFilterOptions,
    FilterGroupLabels,
    TransactionFactoryService,
    AccountHistoryService
} from '@cdux/ng-fragments';

@Injectable()
export class AccountHistoryBusinessService extends AccountHistoryService {
    constructor(
        private _accountDataService: AccountDataService,
        protected _titleCasePipe: TitleCasePipe,
        private _transactionFactory: TransactionFactoryService,
        private _raceDateService: RaceDateService,
        private _resultsDataService: ResultsDataService
    ) {
        super(_transactionFactory, _titleCasePipe);
    }

    public getTransactions(date: Date): Observable<ITransaction[]> {
        return this._accountDataService.getMyAccountHistory(CduxDateUtil.convertDateToWSFormat(date)).pipe(
            map((transactions: IAccountTransaction[]) => {
                const transactionsList = [];
                transactions.forEach((transaction) => {
                    const transactionObj = this._transactionFactory.createTransaction(transaction);
                    if (!!transactionObj) {
                        transactionsList.push(transactionObj);
                    }
                });

                transactionsList.sort(AccountHistoryBusinessService.reverseSortWagersByDate);
                return transactionsList;
            })
        );
    }

    public getTransactionRange(startDate: Date, endDate: Date, page: number = 0, filters: object = null, wagerControlsEnabled: boolean = true, toteDate?: Date): Observable<ITransactionRange> {
        if (!startDate || !endDate) {
            return of(null);
        }
        const utcOffset = - startDate.getTimezoneOffset() / 60; // the offset between local timezone and utc (in hours):
                                                                // For Western Hemisphere it is negative number, for eastern it is positive.
        if (toteDate) {
            this._transactionFactory.evalDate = toteDate; // set the evaluation date in transaction factory to be toteDate
        }
        return this._accountDataService.getMyAccountHistoryRange(CduxDateUtil.convertDateToWSFormat(startDate), CduxDateUtil.convertDateToWSFormat(endDate), page, filters, utcOffset).pipe(
            map((transactionPage: IAccountTransactionPaged) => {
                return this.createTransactionRange(transactionPage);
            })
        );
    }

    public getSummary(startDate: Date, endDate: Date, filters: object = null): Observable<IAccountHistorySummaryDetails> {
        const utcOffset = - startDate.getTimezoneOffset() / 60; // the offset between local timezone and utc (in hours):
        // For Western Hemisphere it is negative number, for eastern it is positive.

        // If filters transactionTypes present and wager is not selected,
        // then simply return null since summary only apply for wager now:
        if (filters
            && filters.hasOwnProperty(FilterGroupLabels.TRANSACTION_TYPE)
            && filters[FilterGroupLabels.TRANSACTION_TYPE].indexOf('wager') === -1) {
            return of(null);
        }
        return this._accountDataService.getAccountHistorySummary(CduxDateUtil.convertDateToWSFormat(startDate), CduxDateUtil.convertDateToWSFormat(endDate), filters, utcOffset).pipe(
            take(1)
        );
    }

    public getFilterOptions(showGreyhounds: boolean=true): Observable<AccountHistoryFilterOptions[]> {
        return combineLatest([
            this.getAccountTransactionTypes(),
            this._getAccountHistoryBetTypes(),
            this._getAccountHistoryTracks(),
            this.getAccountHistoryTrackTypes(showGreyhounds)
        ]).pipe(
            map(([transactionTypes, betTypes, tracks, trackTypes]) => {
                return this.createAccountHistoryFilterOptions(transactionTypes, betTypes, tracks, trackTypes);
            })
        );
    }

    private _getAccountHistoryBetTypes(): Observable<AccountHistoryFilterOptions> {
        return this._accountDataService.getBetTypeHistoryForAccount()
            .pipe(
                map((data: IAccountHistoryBetTypes) => {
                    return this.createBetTypes(data);
                })
            );
    }

    private _getAccountHistoryTracks(): Observable<AccountHistoryFilterOptions> {
        return this._accountDataService.getTrackHistoryForAccount()
            .pipe(
                map((data: IAccountHistoryTracks) => {
                    return this.createTracks(data);
                })
            );
    }

    public getToteDate(): Observable<Date> {
        return this._raceDateService.getToteDate();
    }

    public getExportAccountHistory(start: Date, end: Date): Observable<Blob> {
        return this._accountDataService.exportAccountHistory(start, end);
    }

    public getLastActivityDate() {
        const utcOffset = - (new Date()).getTimezoneOffset() / 60;

        return this._accountDataService.getLastActivityDate(utcOffset);
    }

    public getFinishOrder(transaction: ITransaction): Observable<IAvailableResultsRaceDataResponse> {
        const reqObj: IRaceIdentifier = {
            brisCode: transaction.payoutTrackBrisCode || transaction.brisCode || transaction.bonusWagerBrisCode,
            raceNumber: transaction.payoutRaceNumber || transaction.raceNum || transaction.bonusWagerRace,
            raceDate: transaction.payoutRaceDate || CduxDateUtil.convertDateToWSFormat(transaction.transactionDate) || transaction.bonusWagerRaceDate,
            trackType: transaction.trackType || transaction.bonusWagerTrackType
        };

        return this._resultsDataService.getResultsForRace(reqObj, false);
    }
}
