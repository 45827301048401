import { formatDate } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Self,
    ViewChild
} from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import {
    enumFeatureToggle,
    EventClickType,
    EventClickAttributeType,
    IEventClickDetailsAttribute,
    FeatureToggleDataService,
    CduxDateUtil,
    RaceDateService,
    CduxObjectUtil,
} from '@cdux/ng-common';
import { CduxRequestError, ENVIRONMENT } from '@cdux/ng-core';
import {
    LoadingService,
    LoadingDotsComponent,
    DatepickerLiteComponent,
    DropupService,
    ITransaction,
    ITransactionRange,
    SelectedFilters,
    DatePickerValues,
    ErrorReasons,
    StatusCodes
} from '@cdux/ng-fragments';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { CduxSidebarContentComponent } from 'app/shared/sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SsnCollectionService } from 'app/shared/ssn-collection/services/ssn-collection.service';
import { AccountHistoryBusinessService } from '../../services';

/**
 * Height of the header within the nav-panel/sidebar.
 *
 * @type {number}
 */
const ACTHIS_HEADER_HEIGHT = 60;

/**
 * Height of the Date picker within the nav-panel/sidebar.
 *
 * @type {number}
 */
const ACTHIS_DATEPICK_HEIGHT = 46;

/**
 * Height of the more content within the nav-panel/sidebar.
 *
 * @type {number}
 */
const ACTHIS_SCROLL_HEIGHT = 50;

/**
 * Height of the footer within the nav-panel/sidebar.
 *
 * @type {number}
 */
const ACTHIS_FOOTER_HEIGHT = 48;

enum predefinedRange {
    TODAY,
    YESTERDAY,
    LAST7DAYS,
    LAST30DAYS,
    CUSTOM
}

enum rangeErrors {
    NONE,
    TOO_LONG,
    INVALID_DATES
}

@Component({
    selector: 'cdux-account-history',
    templateUrl: './account-history-sidebar.component.html',
    styleUrls: ['./account-history-sidebar.component.scss'],
    providers: [ DropupService ]
})
export class AccountHistorySidebarComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    @ViewChild(DatepickerLiteComponent) public cduxDatepickerTrigger: DatepickerLiteComponent;
    @ViewChild('dropupPortal') private dropupPortalTplRef;

    /**
     * Exposes the event click types to the template.
     *
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    public selectedDate: Date;
    public isLoaded: boolean = false;
    public isError: boolean = false;
    public loadingDotsComponent = LoadingDotsComponent;
    public transactions: ITransaction[] = [];
    public pendingTransactions: ITransaction[] = [];
    public completeTransactions: ITransaction[] = [];
    public transactionCategories = {};
    public startYear: number;
    public endYear: number;
    public lastDate: Date;
    public isToday: boolean;
    public isTodaySelected: boolean = true;
    public isLastDay: boolean;
    public actHistoryErrorMessage: string;
    public actHistoryErrorMessageHeader: string;
    public isDatepickerDropupOpen: boolean = false;
    public hasFullSsn: boolean;
    public isFinishOrderToggledOn: boolean;
    public isAccountHistoryRangeFTOn: boolean;
    public isAccountHistoryFilterToggledOn: boolean;
    public isIVRPhoneMessageToggledOn: boolean;
    public allowTodaySelection: boolean = true;
    public predefinedSelection = predefinedRange.TODAY;
    public predefinedOptions = predefinedRange;
    public nextPage = null;
    public objectKeys = Object.keys;
    public fromDate: Date;
    public toDate: Date;
    public rangeError = rangeErrors.NONE;
    public rangeErrors = rangeErrors;
    public affiliateId: number;

    public toteDate: Date;
    private _destroy: Subject<boolean> = new Subject();
    private _appliedFilters: SelectedFilters = {};


    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: AccountHistorySidebarComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'history-title',
            translateLanguage: 'my-account',
            eventClickType: EventClickType.ACCOUNT_HISTORY_NAVIGATE_BACK
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */


    constructor(
        private _accountHistoryService: AccountHistoryBusinessService,
        private _changeDetector: ChangeDetectorRef,
        private _environment: ENVIRONMENT,
        private _eventTrackingService: EventTrackingService,
        private _featureToggleService: FeatureToggleDataService,
        private _loadingService: LoadingService,
        private _raceDateService: RaceDateService,
        private _ssnCollectionService: SsnCollectionService,
        @Self() private _datepickerDropupService: DropupService
    ) {
        super();
        this.affiliateId = this._environment.affiliateId;
    }

    ngOnInit() {
        this.hasFullSsn = this._ssnCollectionService.hasFullSsn();
        this.isFinishOrderToggledOn = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.FINISH_ORDER);
        this.isAccountHistoryRangeFTOn = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ACCT_HISTORY);
        this.isAccountHistoryFilterToggledOn = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ACCT_HISTORY_FILTER);
        this.isIVRPhoneMessageToggledOn = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.IVR_WAGER_MSG);

        this.allowTodaySelection = !this.isAccountHistoryRangeFTOn;

        this.predefinedSelection = predefinedRange.TODAY;
        this.selectedDate = this.fromDate = this.toDate = new Date();
        this.updateLastDate();

        // adding start and end years for the date picker
        this.endYear = this.selectedDate.getFullYear();
        if (!this.isAccountHistoryRangeFTOn) {
            this.startYear = this.selectedDate.getFullYear() - +DatePickerValues.NUM_YEARS_TO_SHOW;
        } else {
            this.startYear = this.selectedDate.getFullYear() - +DatePickerValues.NUM_YEARS_TO_SHOW_MAX;
        }
        this.resetIsCurrentDate();

        // initialize using today's date
        if (!this.isAccountHistoryRangeFTOn) {
            this.getTransactions(this.selectedDate);
        } else {
            this.getTodaysTransactions();
        }

        this._datepickerDropupService.initDropup(this.dropupPortalTplRef, 'SELECT DATE');
        this._datepickerDropupService.toggleStateSubject.subscribe(
            (toggleTo) => this.isDatepickerDropupOpen = toggleTo
        );

        this._raceDateService.getToteDate()
            .pipe(takeUntil(this._destroy))
            .subscribe(toteDate => { this.toteDate = toteDate });

    }

    ngOnDestroy() {
        this._destroy.next(true);
        this._destroy.complete();
    }

    /**
     * Trackby for template performance
     *
     * @param index
     * @param transaction
     */
    public trackByTransaction(index: number, transaction: ITransaction) {
        return transaction.transactionId;
    }

    /**
     * Updates the last date which is back three years from current date
     * @private
     */
    private updateLastDate() {
        this.lastDate = new Date(new Date().getFullYear() - +DatePickerValues.NUM_YEARS_TO_SHOW, new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        // it check if the current date fall in feb 29 as three years before feb 29 doesn't exist so it changes to last day of feb on that year
        if (this.lastDate.getMonth() > new Date().getMonth()) {
            const selectedMonthTotalDays = new Date(this.lastDate.getFullYear(), this.lastDate.getMonth(), 0).getDate();
            this.lastDate = new Date(this.lastDate.getFullYear(), this.lastDate.getMonth() - 1, selectedMonthTotalDays, 0, 0, 0, 0);
        }
    }

    /**
     * Creates pending and completed list for a given date
     * @param date
     */
    private getTransactions(date: Date) {
        this.isLoaded = false;
        this._loadingService.register('accountHistoryOverlay');
        this.pendingTransactions = [];
        this.completeTransactions = [];
        this.communicateContentSizeChange();

        this._accountHistoryService.getTransactions(date).pipe(take(1)).subscribe((data) => {
            this._loadingService.resolve('accountHistoryOverlay', 1000, 'success');
            setTimeout(() => {
                data.forEach(transaction => {
                    if (transaction.isPending || transaction.isFrozen) {
                        this.pendingTransactions.push(transaction);
                    } else {
                        this.completeTransactions.push(transaction);
                    }
                });
                this.isLoaded = true;
                this._changeDetector.detectChanges();
                this.communicateContentSizeChange();
            }, 1000);
        }, (response: CduxRequestError) => {
            // look at data object of error to determine if this is a waiting room response, if so show waiting room
            this._loadingService.resolve('accountHistoryOverlay', -1);
            this.isError = true;
            if (response.data && response.data.status === StatusCodes.WAITING_ROOM_DISPLAY_CODE) { // agreed upon status code with prod engineering
                this.actHistoryErrorMessage = ErrorReasons.WAITING_ROOM_ACCT_HIS;
                this.actHistoryErrorMessageHeader = ErrorReasons.WAITING_ROOM_HEADER;
            } else {
                this.actHistoryErrorMessage = ErrorReasons.ERROR_MESSAGE_ACCT_HIS;
                this.actHistoryErrorMessageHeader = ErrorReasons.ERROR_MESSAGE_HEADER;
            }
        });
    }

    /**
     * Retrieves a single page of transactions
     *
     * @param from
     * @param to
     * @param page
     */
    private _getTransactionPage(from: Date, to: Date, page: number = 0): Observable<ITransactionRange> {
        // TODO: pipe operators duplicated in account-history.component.ts should be moved to account-history.business.service.ts
        return this._accountHistoryService.getTransactionRange(from, to, page, this._appliedFilters, undefined, this.toteDate).pipe(
            take(1),
            catchError((response: CduxRequestError) => {
                this._loadingService.resolve('accountHistoryOverlay', -1);
                this.isError = true;
                if (response.data && response.data.status === StatusCodes.WAITING_ROOM_DISPLAY_CODE) { // agreed upon status code with prod engineering
                    this.actHistoryErrorMessage = ErrorReasons.WAITING_ROOM_ACCT_HIS;
                    this.actHistoryErrorMessageHeader = ErrorReasons.WAITING_ROOM_HEADER;
                } else {
                    this.actHistoryErrorMessage = ErrorReasons.ERROR_MESSAGE_ACCT_HIS;
                    this.actHistoryErrorMessageHeader = ErrorReasons.ERROR_MESSAGE_HEADER;
                }
                return of({ transactionList: [], nextPage: null });
            }),
        );
    }

    /**
     * Get a list of transaction based on the range
     *
     * @param from
     * @param to
     */
    private _getTransactionByRange(from: Date, to?: Date): Promise<ITransaction[]> {
        this._loadingService.register('accountHistoryOverlay');
        if (!to) {
            to = from;
        }

        this.isError = false;

        return new Promise((resolve) => {
            this._getTransactionPage(from, to).subscribe((transactionPage: ITransactionRange) => {
                this.transactionCategories = {};
                this.pendingTransactions = [];
                this.completeTransactions = [];
                this.transactions = transactionPage.transactionList;
                this.nextPage = transactionPage.nextPage;
                this._loadingService.resolve('accountHistoryOverlay', -1);
                this.isLoaded = true;
                resolve(this.transactions);
            });
        });
    }

    /**
     * Groups the transaction by transaction date
     */
    private _categorizeByDate() {
        this.transactionCategories = {};
        this.transactions.forEach((transaction) => {
            const transactionDate = formatDate(transaction.transactionDate, 'MMMM d, y', 'en-US');
            if (!this.transactionCategories[transactionDate]) {
                this.transactionCategories[transactionDate] = [];
            }
            this.transactionCategories[transactionDate].push(transaction);
        });
    }


    /**
     * Preset filter for todays transasctions
     */
    public getTodaysTransactions() {
        this._appliedFilters = {};
        this.predefinedSelection = predefinedRange.TODAY;
        this.rangeError = rangeErrors.NONE;
        this.fromDate = new Date();
        this.toDate = new Date();
        this.isLoaded = false;
        this.isTodaySelected = true;
        this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_TODAY);
        this._getTransactionByRange(this.fromDate, this.toDate).then(() => {
            this.transactions.forEach(transaction => {
                if (transaction.isPending || transaction.isFrozen) {
                    this.pendingTransactions.push(transaction);
                } else {
                    this.completeTransactions.push(transaction);
                }
            });
        });
    }

    /**
     * Preset filter for yesterdays transactions
     */
    public getYesterdaysTransactions() {
        this.predefinedSelection = predefinedRange.YESTERDAY;
        this.rangeError = rangeErrors.NONE;
        this.fromDate = moment().subtract(1, 'days').toDate();
        this.toDate = this.fromDate;
        this.isLoaded = false;
        this.isTodaySelected = false;
        this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_YESTERDAY);
        this._getTransactionByRange(this.fromDate, this.toDate).then(() => {
            this._categorizeByDate();
        });
    }

    /**
     * Preset filter for the last 7 days including today
     */
    public getLast7DaysTransactions() {
        this.predefinedSelection = predefinedRange.LAST7DAYS;
        this.rangeError = rangeErrors.NONE;
        this.fromDate = moment().subtract(8, 'days').toDate();
        this.toDate = moment().subtract(1, 'days').toDate();
        this.isLoaded = false;
        this.isTodaySelected = false;
        this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_LASTSEVEN);
        this._getTransactionByRange(this.fromDate, this.toDate).then(() => {
            this._categorizeByDate();
        });
    }

    /**
     * Preset filter for the last 30 days including today
     */
    public getLast30DaysTransactions() {
        this.predefinedSelection = predefinedRange.LAST30DAYS;
        this.rangeError = rangeErrors.NONE;
        // from/to should NOT include TODAY, so we're offsetting the entire range by -1
        this.fromDate = moment().subtract(31, 'days').toDate();
        this.toDate = moment().subtract(1, 'days').toDate();
        this.isLoaded = false;
        this.isTodaySelected = false;
        this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_LASTTHIRTY);
        this._getTransactionByRange(this.fromDate, this.toDate).then(() => {
            this._categorizeByDate();
        });
    }

    /**
     * Loads the next page of transactions or a specific page
     *
     * @param page
     */
    public loadNextPage(page) {
        this._getTransactionPage(this.fromDate, this.toDate, page).subscribe((transactionPage) => {
            // append transactions
            this.transactions = this.transactions.concat(transactionPage.transactionList);

            // categorize by day if needed
            if (this.predefinedSelection !== this.predefinedOptions.TODAY) {
                this._categorizeByDate();
            } else {
                // otherwise, categorize by active/ended
                this.pendingTransactions = [];
                this.completeTransactions = [];
                transactionPage.transactionList.forEach((transaction) => {
                    if (transaction.isPending || transaction.isFrozen) {
                        this.pendingTransactions.push(transaction);
                    } else {
                        this.completeTransactions.push(transaction);
                    }
                });
            }
            this.nextPage = transactionPage.nextPage;
        });
    }

    /**
     * Determines if there are any errors in range selections
     * TOO_LONG - date range is longer than one year
     * INVALID_DATES - date range from is after the to dates
     */
    private _determineDateErrors(): boolean {

        if (this.toDate.getTime() < this.fromDate.getTime()) {
            this.rangeError = rangeErrors.INVALID_DATES;
            return true;
        }

        // date range contain future date (either in fromDate or toDate)
        if (CduxDateUtil.isAfterDay(this.fromDate, this.toteDate)
            || CduxDateUtil.isAfterDay(this.toDate, this.toteDate)) {
            this.rangeError = rangeErrors.INVALID_DATES;
            return true;
        }

        const rangeLength = (this.toDate.getTime() - this.fromDate.getTime()) / (1000 * 3600 * 24);

        if (rangeLength > 364) {
            this.rangeError = rangeErrors.TOO_LONG;
            return true;
        }

        this.rangeError = rangeErrors.NONE;
        return false;
    }

    private resetIsCurrentDate() {
        this.isToday = CduxDateUtil.isToday(this.selectedDate);
        this.isLastDay = this.selectedDate.toDateString() === this.lastDate.toDateString();
        this.isError = false;
    }

    public previousDay() {
        this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - 1));
        this.resetIsCurrentDate();
        this.getTransactions(this.selectedDate);
    }

    public nextDay() {
        this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + 1));
        this.resetIsCurrentDate();
        this.getTransactions(this.selectedDate);
    }

    public toggleExpandedView(transaction: ITransaction): void {
        const allTransactions = [].concat(this.pendingTransactions, this.completeTransactions);
        this.closeExpandedTransaction(allTransactions, transaction);
    }

    /**
     * When all of the transactions contract, we need to recognize the fact that we may no longer
     * allow a fixed height, which sets the scrolling.
     */
    public resetSize() {
        this.communicateContentSizeChange();
    }

    /**
     * when ever the date picker date is selected or closed this method will be called
     * @param {Date} date
     */
    public onChangeDate(date: Date | boolean) {
        this._datepickerDropupService.closeDropup();
        if (typeof (date) !== 'boolean') {
            if (date <= new Date()) {
                this.selectedDate = date as Date;
                this.resetIsCurrentDate();
                this.getTransactions(this.selectedDate);
            }
        }
    }

    /**
     * when ever the date picker date is selected or closed this method will be called
     * @param {Date} date
     */
    public onChangeDateRange(dateRange: any) {
        this.predefinedSelection = this.predefinedOptions.CUSTOM;
        this.fromDate = dateRange.startDate;
        this.toDate = dateRange.endDate;
        this.isTodaySelected = (this.isDateToday(this.fromDate) || this.isDateToday(this.toDate));
        this._datepickerDropupService.closeDropup();
        if (this._determineDateErrors()) {
            this.isError = true;
        } else {
            const fromDate: IEventClickDetailsAttribute = {
                attrId: EventClickAttributeType.ACCOUNT_HISTORY_SelectedDateRange_FromDate,
                data: this.fromDate
            };
            const toDate: IEventClickDetailsAttribute = {
                attrId: EventClickAttributeType.ACCOUNT_HISTORY_SelectedDateRange_ToDate,
                data: this.toDate
            };
            this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_SelectedDateRange, [fromDate, toDate]);
            this._getTransactionByRange(dateRange.startDate, dateRange.endDate).then(() => {
                this._categorizeByDate();
            });
        }
    }

    public isDateToday(txnDate: Date) {
        return CduxDateUtil.isSameISODay(this.toteDate, txnDate);
    }

    /**
     * This occurs when the calendar toggles its state.
     *
     * @param {enumToggleState} toggleState
     */
    public toggleCalendar() {
        this._datepickerDropupService.toggleState();
        if (this.isAccountHistoryRangeFTOn) {
            this._eventTrackingService.logClickEvent(EventClickType.ACCOUNT_HISTORY_PICK_A_DATE);
        }
        this._changeDetector.detectChanges();
    }

    public wagerCanceled(transaction: ITransaction) {
        // remove this transaction from "pending" group
        this.pendingTransactions = this.pendingTransactions.filter(activeTransaction => activeTransaction.transactionId !== transaction.transactionId);
        // add this transaction to "completed" group
        this.completeTransactions.push(transaction);
        this.completeTransactions.sort((a, b) => b.transactionDate.getTime() - a.transactionDate.getTime());
        // refresh today's transactions (since we can't cancel past transactions)
        // to ensure we get the updated cancelled transaction
        this.getTodaysTransactions();
    }

    /**
     * Find the expanded transaction and collapse it as other transaction is expanded
     * @param {CduxTransactionModel[]} - List of all transactions
     * @param {CduxTransactionModel} - Transaction to remain in expanded state
     */
    private closeExpandedTransaction(transactionList: ITransaction[], nextTransaction: ITransaction): void {
        // There is at most one transaction expanded at a time
        transactionList.filter(transaction => transaction.isExpanded === true)
            .forEach(transaction => transaction.isExpanded = false);

        nextTransaction.isExpanded = true;
        this._changeDetector.detectChanges();
    }

    /**
     * Emits a content size change with an override for size of the scrolling area, if available.
     * To make it respond to a particular element's height, set a #scrollingArea element.
     */
    public communicateContentSizeChange() {
        // Unfortunately, this gets called on change detection, presumably because it's
        // bound to a portal. This results in this emitting the client height at inconvenient
        // times, like when it hasn't fully rerendered. So I'm setting it in a timeout
        // to make it wait until it's done. And yes, I've tried many things to get this
        // to work without it. Good luck trying, if you're really determined.
        //  I'm adding Bets Components height from the scrollHeight to account for the bets container component.
        setTimeout(() => {
            // In IE the clientHeight is giving different values so using scrollHeight.
            this.contentSizeChange.emit(this.scrollingArea.nativeElement.scrollHeight + (ACTHIS_HEADER_HEIGHT + ACTHIS_DATEPICK_HEIGHT + ACTHIS_SCROLL_HEIGHT + ACTHIS_FOOTER_HEIGHT));
        });
    }

    public updateFilters(selectedFilters: object) {
        if (!CduxObjectUtil.deepEquals(selectedFilters, this._appliedFilters)) {
            this._appliedFilters = selectedFilters;
            this.isLoaded = false;
            this._getTransactionByRange(this.fromDate, this.toDate).then(() => {
                this._categorizeByDate();
            });
        }
    }

    public isToteDay (txnDate: Date) {
        return moment(txnDate).isSame(this.toteDate, 'day');
    }
}
