<div *ngIf="isSummarySurveyEnabled === true" class="summary_feedback">
    <div class="summary_feedback-message">Help us improve Summary <button class="summary_feedback-button" (click)="openSurvey()">SHARE FEEDBACK</button></div>
</div>

<div *ngIf="summaryRaceStats" class="program-racestats-container">
    <div class="program-summary-racestats-container">
        <h2>RACE STATS</h2>
    </div>
        <div class="race-stats-race-info">
            <span>{{ summaryRaceStats.simulcastBRISCode }}&nbsp;</span>
            <span>{{ formatClaimingRaceClassification(summaryRaceStats.raceClassification,  summaryRaceStats.claimingPrice) }}&nbsp;</span>
            <span *ngIf="summaryRaceStats.claimingPrice > 0">${{  summaryRaceStats.claimingPrice | number:'1.0':'en-US' }}&nbsp;</span>
            <span>| Purse: {{ summaryRaceStats.purse | formatPurse }}&nbsp;</span>
            <span *ngIf="summaryRaceStats.ageRestriction">| {{ summaryRaceStats.ageRestriction }}&nbsp;</span>
            <span *ngIf="summaryRaceStats.sexRestriction">| {{ summaryRaceStats.sexRestriction }}&nbsp;</span>
            <span *ngIf="parsDistance">| {{ parsDistance }}&nbsp;</span>
            <span *ngIf="summaryRaceStats.surface">| {{ summaryRaceStats.surface }}&nbsp;</span>
            <span>| PARS: | E1: {{ summaryRaceStats.paceParEarly1 }} | E2: {{ summaryRaceStats.paceParEarly2 }} | LP: {{ summaryRaceStats.paceParLatePace }} 
            | SPD: {{ summaryRaceStats.speedPar }}</span>
        </div>
    <div *ngIf="summaryRaceStats?.dateType"class="last-stats-container">
        <div class="last-stats-container-header"> Race Type Stats for {{ summaryRaceStats.dateType }}</div>
        <table class="last-stats-table">
            <tbody class="last-stats-table-body">
                    <tr class="last-stats-table-categories">
                        <th class="last-stats-cell-header">Race Type</th>
                        <th class="last-stats-cell-header"># Races</th>
                        <th class="last-stats-cell-header">FAV Win%</th>
                        <th class="last-stats-cell-header">FAV ITM Win%</th>
                        <th class="last-stats-cell-header">FAV $3 ROI</th>    
                    </tr>
                    <tr class="last-stats-table-categories">
                        <td class="last-stats-cell-info">{{ raceType }}</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.numberRaces }}</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.winPercentage | number: '1.1-1' }}%</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.itemPercentage | number: '1.1-1' }}%</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.favoriteRoi }}</td>
                    </tr>
            </tbody>
        </table>
        <table class="last-stats-table">    
            <tbody class="last-stats-table-body">  
                    <tr class="last-stats-table-categories">
                        <th class="last-stats-cell-header">Average Field Size</th>
                        <th class="last-stats-cell-header">Median $2 Win Payoff</th>
                        <th class="last-stats-cell-header">% Winners &lt;5/1</th>
                        <th class="last-stats-cell-header">% Winners &gt;=5/1&lt;10/1</th>
                        <th class="last-stats-cell-header">% Winners &gt;=10/1</th>
                    </tr>
                    <tr class="last-stats-table-categories">
                        <td class="last-stats-cell-info">{{ summaryRaceStats.averageFieldSize | number: '1.1-1' }}</td>
                        <td class="last-stats-cell-info">${{ summaryRaceStats.medianOddsWin }}</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.winPerShort | number: '1.1-1' }}%</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.winPerMid | number: '1.1-1' }}%</td>
                        <td class="last-stats-cell-info">{{ summaryRaceStats.winPerLong | number: '1.1-1' }}%</td>
                    </tr>
            </tbody>
        </table>
    </div>
    <div class="track-bias-container" [class.is-compact]="isCompact">
        <table class="track-bias-table">
            <tbody class="track-bias-table-container">
                <div class="track-bias-stats-header">{{ summaryRaceStats.meetSurface }} {{ summaryRaceStats.meetDistance }}
                    Track Bias Stats: Meet ({{ formatDate(summaryRaceStats.meetBeginDate)}} - {{ formatDate(summaryRaceStats.meetEndDate) }})</div>
                <tr class="track-bias-stats-top-table">
                    <th class="track-bias-table-header"># Races</th>
                    <th class="track-bias-table-sub-header">% Wire</th>
                    <th class="track-bias-table-sub-header">Speed Bias</th>
                        <div class="sub-stats-table">
                            <div class="track-bias-sub-table-header">WNR Avg BL</div>
                            <div class="track-bias-sub-table-sub-header">1stCall</div>
                            <div class="track-bias-sub-table-sub-header">2ndCall</div>
                        </div>
                </tr>
                <tr>
                    <th class="track-bias-top-table-col-header">{{ summaryRaceStats.meetCountRaces }}</th>
                    <td class="track-bias-top-table-info">{{ summaryRaceStats.meetWireToWire | number: '1.1-1' }}%</td>
                    <td class="track-bias-top-table-info">{{ summaryRaceStats.meetSpeedBias | number: '1.1-1' }}%</td>
                    <td class="track-bias-top-sub-table-info"> {{ summaryRaceStats.meetWinrateAverageBl1 | number: '1.2-2' }}</td>
                    <td class="track-bias-top-sub-table-info">{{ summaryRaceStats.meetWinrateAverageBl2 | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Run Style</th>
                    <th class="track-bias-bottom-table-header-es">Early Speed</th>
                    <th class="track-bias-bottom-table-header-ls">Late Speed</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header"></th>  <!--empty cell for styles -->
                    <th class="track-bias-bottom-table-sub-header">E</th>
                    <th class="track-bias-bottom-table-sub-header">E/P</th>
                    <th class="track-bias-bottom-table-sub-sub-header">P</th>
                    <th class="track-bias-bottom-table-sub-sub-header">S</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Impact Values</th>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.meetRs1Impact > 1.08}">{{ summaryRaceStats.meetRs1Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.meetRs2Impact > 1.08}">{{ summaryRaceStats.meetRs2Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.meetRs3Impact > 1.08}">{{ summaryRaceStats.meetRs3Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.meetRs4Impact > 1.08}">{{ summaryRaceStats.meetRs4Impact | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">% Races Won</th>
                    <td class="track-bias-table-info">{{ summaryRaceStats.meetRs1Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-info">{{ summaryRaceStats.meetRs2Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.meetRs3Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.meetRs4Percent | number: '1.1-1'}}%</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Post Bias</th>
                    <th class="track-bias-bottom-table-sub-header">RAIL</th>
                    <th class="track-bias-bottom-table-sub-header">1-3</th>
                    <th class="track-bias-bottom-table-sub-sub-header">4-7</th>
                    <th class="track-bias-bottom-table-sub-sub-header">8+</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Impact Values</th>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.meetPost1Impact > 1.08}">{{ summaryRaceStats.meetPost1Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.meetPost2Impact > 1.08}">{{ summaryRaceStats.meetPost2Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.meetPost3Impact > 1.08}">{{ summaryRaceStats.meetPost3Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.meetPost4Impact > 1.08}">{{ summaryRaceStats.meetPost4Impact | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">AVG Win %</th>
                    <td class="track-bias-table-info">{{ summaryRaceStats.meetPost1Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-info">{{ summaryRaceStats.meetPost2Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.meetPost3Percent | number: '1.1-1'}}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.meetPost4Percent | number: '1.1-1'}}%</td>
                </tr>
            </tbody>
        </table>
        <table class="track-bias-table">
            <tbody class="track-bias-table-container">
                <div class="track-bias-stats-header">{{ summaryRaceStats.weekSurface }} {{ summaryRaceStats.weekDistance }}
                    Track Bias Stats: Week ({{ formatDate(summaryRaceStats.weekBeginDate) }} - {{ formatDate(summaryRaceStats.weekEndDate) }})</div>
                <tr class="track-bias-stats-top-table">
                    <th class="track-bias-table-header"># Races</th>
                    <th class="track-bias-table-sub-header">% Wire</th>
                    <th class="track-bias-table-sub-header">Speed Bias</th>
                        <div class="sub-stats-table">
                            <div class="track-bias-sub-table-header">WNR Avg BL</div>
                            <div class="track-bias-sub-table-sub-header">1stCall</div>
                            <div class="track-bias-sub-table-sub-header">2ndCall</div>
                        </div>
                </tr>
                <tr>
                    <th class="track-bias-top-table-col-header">{{ summaryRaceStats.weekCountRaces }}</th>
                    <td class="track-bias-top-table-info">{{ summaryRaceStats.weekWireToWire | number: '1.1-1' }}%</td>
                    <td class="track-bias-top-table-info">{{ summaryRaceStats.weekSpeedBias | number: '1.1-1' }}%</td>
                    <td class="track-bias-top-sub-table-info"> {{ summaryRaceStats.weekWinrateAverageBl1 | number: '1.2-2' }}</td>
                    <td class="track-bias-top-sub-table-info">{{ summaryRaceStats.weekWinrateAverageBl2 | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Run Style</th>
                    <th class="track-bias-bottom-table-header-es">Early Speed</th>
                    <th class="track-bias-bottom-table-header-ls">Late Speed</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header"></th>  <!--empty cell for styles -->
                    <th class="track-bias-bottom-table-sub-header">E</th>
                    <th class="track-bias-bottom-table-sub-header">E/P</th>
                    <th class="track-bias-bottom-table-sub-sub-header">P</th>
                    <th class="track-bias-bottom-table-sub-sub-header">S</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Impact Values</th>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.weekRs1Impact > 1.08}">{{ summaryRaceStats.weekRs1Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.weekRs2Impact > 1.08}">{{ summaryRaceStats.weekRs2Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.weekRs3Impact > 1.08}">{{ summaryRaceStats.weekRs3Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.weekRs4Impact > 1.08}">{{ summaryRaceStats.weekRs4Impact | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">% Races Won</th>
                    <td class="track-bias-table-info">{{ summaryRaceStats.weekRs1Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-info">{{ summaryRaceStats.weekRs2Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.weekRs3Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.weekRs4Percent | number: '1.1-1' }}%</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Post Bias</th>
                    <th class="track-bias-bottom-table-sub-header">RAIL</th>
                    <th class="track-bias-bottom-table-sub-header">1-3</th>
                    <th class="track-bias-bottom-table-sub-sub-header">4-7</th>
                    <th class="track-bias-bottom-table-sub-sub-header">8+</th>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">Impact Values</th>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.weekPost1Impact > 1.08}">{{ summaryRaceStats.weekPost1Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-info" [ngClass]="{'is-impact': summaryRaceStats.weekPost2Impact > 1.08}">{{ summaryRaceStats.weekPost2Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info"[ngClass]="{'is-impact': summaryRaceStats.weekPost3Impact > 1.08}">{{ summaryRaceStats.weekPost3Impact | number: '1.2-2' }}</td>
                    <td class="track-bias-table-sub-info" [ngClass]="{'is-impact': summaryRaceStats.weekPost4Impact > 1.08}">{{ summaryRaceStats.weekPost4Impact | number: '1.2-2' }}</td>
                </tr>
                <tr>
                    <th class="track-bias-bottom-table-col-header">AVG Win %</th>
                    <td class="track-bias-table-info">{{ summaryRaceStats.weekPost1Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-info">{{ summaryRaceStats.weekPost2Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.weekPost3Percent | number: '1.1-1' }}%</td>
                    <td class="track-bias-table-sub-info">{{ summaryRaceStats.weekPost4Percent | number: '1.1-1' }}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
