<div class="entry" [ngClass]="sectionClass" [class.has-badge]="isFavoriteRunner || isFavoriteJockey || isFavoriteTrainer" [class.is-compact]="isCompact"
    [class.is-open]="isShowingOddsTrend"
    [class.is-scratched]="!entry.Padded && entry.Scratched">

    <div class="entry_col_num" [class.has-silk]="displaySilk  && section !== ViewSectionEnum.PROGRAM_SUMMARY">
      <div class="silk-saddle-cloth-container">
        <div class="saddle-cloth program-number" [ngClass]="entry.BettingInterest | cduxSaddleClothClass: track?.TrackType">{{ entry.ProgramNumber }}</div>
        <div class="silk" *ngIf="displaySilk"><img *ngIf="!entry.Padded && entry.SilkUriPath && section !== ViewSectionEnum.PROGRAM_SUMMARY" [src]="entry.SilkUriPath" /></div>
        <div class="program-post-position" *ngIf="!isBasicClass && !entry.Padded && entry.PostPosition && section !== ViewSectionEnum.PROGRAM_SUMMARY">PP {{entry.PostPosition}}</div>
      </div>
    </div>
    <div class="entry_col_checkio">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!entry.Padded; else paddedEntry">
        <div class="entry_col_odds" [class.is-favorite]="entry.IsFavorite" [class.clickable]="canShowOddsTrend()"
            (click)="oddsDetails()">
            <span class="main-detail odds-main-detail">
                {{ entry.OddsToDisplay | trimSlash }}

                <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED"
                    [ngSwitch]="areOddsTrending(entry.OddsTrend?.current)">
                    <div *ngSwitchCase="1" class="trend-up-arrow"></div>
                    <div *ngSwitchCase="-1" class="trend-down-arrow"></div>
                </ng-container>
            </span>
            <div class="sub-detail" *ngIf="!isBasicClass && !isCompact && entry.MLOddsToDisplay">
              <span>M:</span>
              <span class="odds-sub-detail"> {{ entry.MLOddsToDisplay | trimSlash }} </span>
            </div>
        </div>
        <div class="entry_col_ml odds-morning-line">{{entry.MLOddsToDisplay | trimSlash}}</div>
        <div class="entry_col_pl odds-profit-line" [class.is-better-than-ml]="entry.ProfitLineOverlay" *ngIf="isProfitlineOddsEnabled">{{entry.PLOddsToDisplay | trimSlash}}</div>

        <div class="entry_col_runner" [class.no-badge]="!isFavoriteRunner && (isFavoriteJockey || isFavoriteTrainer) && !isSmallGlass && !isBasicClass">
            <div *ngIf="section !== ViewSectionEnum.PROGRAM_BASIC" class="entry_col-badge-row">
                <cdux-my-fav [id]="'icon-fav-badge-'+entry.EntryId" showText *ngIf="isFavoriteRunner && isTbredTrack"></cdux-my-fav>
                <span *ngIf="entry.ExpertPickRank && isExpertPicksEnabled && showExpertPick" class="expert-pick"><b>expert</b> {{ entry.ExpertPickRank | formatRank }} pick</span>
            </div>
            <div class="main-detail">
                <span class="entry-runner-name" (click)="runnerDetails()" [class.active]="hasRunnerDetails()"
                    [class.expanded]="showingDetailsType === enumDetailsType.RUNNER || showingDetailsType === enumDetailsType.RUNNER_GH">{{
                    entry.Name }}</span>

                <ng-container *ngIf="view !== WageringViewEnum.MOBILE; else mobileView">
                    <cdux-tooltip  class="program-tooltip tooltip-scratch-indicator" [activatedOn]="'hover'" [position]="'bottom'"  *ngIf="entry.MainTrackOnly === true">
                        <span tooltip-toggle>MTO</span>
                        <div tooltip-content class="program-tooltip">
                            <span>MAIN-TRACK-ONLY</span>
                        </div>
                    </cdux-tooltip>
                    <cdux-tooltip  class="program-tooltip tooltip-scratch-indicator" [activatedOn]="'hover'" [position]="'bottom'" *ngIf="entry.AlsoEligible === true">
                        <span tooltip-toggle>AE</span>
                        <div tooltip-content class="program-tooltip">
                            <span>ALSO ELIGIBLE</span>
                        </div>
                    </cdux-tooltip>
                </ng-container>
                <ng-template #mobileView>
                    <span  class="scratch-indicator" *ngIf="entry.MainTrackOnly === true">MTO</span>
                    <span  class="scratch-indicator" *ngIf="entry.AlsoEligible === true">AE</span>
                </ng-template>

            </div>
            <span class="sub-detail entry-adv-runner-info" *ngIf="!isBasicClass && section !== ViewSectionEnum.PROGRAM_SUMMARY">
                <ng-container *ngIf="!isCompact; else compactRunnerInfo">{{ advancedRunnerInfo }}</ng-container>
                <ng-template #compactRunnerInfo>
                    <div *ngIf="!isGreyTrack && hasJockeyName()">
                        <span title="{{ jockeyName }}" [class.active]="hasJockeyDetails()"
                            [class.expanded]="showingDetailsType === enumDetailsType.JOCKEY || showingDetailsType === enumDetailsType.DRIVER"
                            (click)="jockeyDetails()"><ng-container *ngIf="entry.JockeyChange">*</ng-container>{{ jockeyName }}</span>
                        <span *ngIf="!entry.Weight || !(entry.JockeyChange && entry.Apprentice); else apprenticeWeight">{{ entry.Weight ? ', ' + entry.Weight + ' lbs' : '' }}</span>
                        <ng-template #apprenticeWeight><span>, -- lbs</span></ng-template>
                    </div>
                    <div>
                        <span title="{{ trainerName }}" [class.active]="hasTrainerDetails()"
                            [class.expanded]="showingDetailsType === enumDetailsType.TRAINER"
                            (click)="trainerDetails()">{{ trainerName }}</span>
                        <span *ngIf="isGreyTrack">{{ entry.Weight ? ', ' + entry.Weight + ' lbs' : '' }}</span>
                    </div>
                </ng-template>
            </span>
        </div>

        <div class="entry_col_med-weight">
            <span class="entry_col_med-weight__med">{{ entry.FormattedMedication }}</span>
            <span *ngIf="!entry.Weight || !(entry.JockeyChange && entry.Apprentice); else apprenticeWeight" class="entry_col_med-weight__weight">{{ entry.Weight }}</span>
            <ng-template #apprenticeWeight><span>--</span></ng-template>
            <span class="entry_col_med-weight__blinkers">{{ entry.Blinkers }}</span>
        </div>

        <div class="entry_col_jockey" [class.no-badge]="!isFavoriteJockey && (isFavoriteRunner || isFavoriteTrainer) && !isBasicClass">
            <div *ngIf="section !== ViewSectionEnum.PROGRAM_BASIC" class="entry_col-badge-row">
                <cdux-my-fav [id]="'icon-fav-badge-'+entry.JockeyId" showText *ngIf="isFavoriteJockey"></cdux-my-fav>
            </div>
            <span class="main-detail entry-jockey-name" (click)="jockeyDetails()" [class.active]="hasJockeyDetails()"
                [class.expanded]="showingDetailsType === enumDetailsType.JOCKEY || showingDetailsType === enumDetailsType.DRIVER">
                <ng-container *ngIf="entry.JockeyChange">*</ng-container>{{ jockeyName }}
            </span>
            <div class="sub-detail" *ngIf="!isBasicClass && !entry.JockeyChange && hasJockeyDetails()">
                <span class="trainer-jockey-win-percent jockey-percent" *ngIf="jockeyWinPct !== null && entry.JockeyId > 0 || isHarnessTrack">{{ jockeyWinPct | percent:'1.0-0' }}</span>
                <span class="entry-jockey-summary" *ngIf="entry.JockeyId > 0 || isHarnessTrack"> {{ jockeySummary }} </span>
            </div>
        </div>

        <div class="entry_col_trainer" [class.no-badge]="!isFavoriteTrainer && (isFavoriteJockey || isFavoriteRunner) && !isBasicClass">
            <div *ngIf="section !== ViewSectionEnum.PROGRAM_BASIC" class="entry_col-badge-row">
                <cdux-my-fav [id]="'icon-fav-badge-'+entry.TrainerId" showText *ngIf="isFavoriteTrainer"></cdux-my-fav>
            </div>
            <span class="main-detail entry-trainer-name" (click)="trainerDetails()" [class.active]="hasTrainerDetails()"
                [class.expanded]="showingDetailsType === enumDetailsType.TRAINER || showingDetailsType === enumDetailsType.TRAINER_HARN">{{
                trainerName }}</span>
            <div class="sub-detail" *ngIf="!isBasicClass && hasTrainerDetails()">
              <span class="trainer-jockey-win-percent trainer-percent" *ngIf="trainerWinPct !== null && entry.TrainerId > 0 || isHarnessTrack">{{ trainerWinPct | percent:'1.0-0' }}</span>
              <span class="entry-trainer-summary" *ngIf="entry.TrainerId > 0 || isHarnessTrack">{{ trainerSummary }}</span>
            </div>
        </div>

        <div class="entry_col_sire-dam" [class.no-badge]="(isFavoriteTrainer || isFavoriteRunner || isFavoriteJockey) && !isBasicClass">
            <span class="main-detail entry-sire-name" (click)="sireDetails()" [class.active]="hasSireDetails()"
                [class.expanded]="showingDetailsType === enumDetailsType.SIRE">{{ entry.SireName || '-' }}</span>
            <span class="sub-detail entry-dam-name" (click)="damDetails()" [class.active]="hasDamDetails()"
                [class.expanded]="showingDetailsType === enumDetailsType.DAM">{{ entry.DamName || '-' }}</span>
        </div>

        <div class="entry_col_run-style">
            {{ !isHarnessTrack ? runStyle : '' }}
        </div>

        <div class="entry_col_days-off">
            {{ isTbredTrack ? entry.DaysOff : '' }}
        </div>

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_SPEED">

            <ng-container *ngIf="isHarnessTrack">
                <ng-container *ngTemplateOutlet="powerRatingCol; context: {rankings: rankings, entry: entry}">
                </ng-container>
            </ng-container>

            <div *ngIf="isTbredTrack" class="entry_col_avg-speed">
                <div class="ranking-display" *ngIf="rankings && rankings['AverageSpeedLast3'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AverageSpeedLast3
                            }}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
            <div *ngIf="isHarnessTrack" class="entry_col_avg-speed">
                <div class="ranking-display" *ngIf="rankings && rankings['AverageSpeed'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AverageSpeed }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div *ngIf="!isHarnessTrack" class="entry_col_avg-distance">
                <div class="ranking-display" *ngIf="rankings && rankings['AverageSpeed'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AverageSpeed }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div *ngIf="isTbredTrack" class="entry_col_best-speed">
                <div class="ranking-display" *ngIf="rankings && rankings['BestSpeedAtDistance'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{
                            entry.BestSpeedAtDistance }}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
            <div *ngIf="isHarnessTrack" class="entry_col_best-speed">
                <div class="ranking-display" *ngIf="rankings && rankings['BestSpeed'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.BestSpeed }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_CLASS">
            <div *ngIf="isTbredTrack" class="entry_col_prime-p">
                <div class="ranking-display" *ngIf="rankings && rankings['PrimePower'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.PrimePower |
                            number:'1.0-0'}}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <ng-container *ngIf="isHarnessTrack">
                <ng-container *ngTemplateOutlet="powerRatingCol; context: {rankings: rankings, entry: entry}">
                </ng-container>
            </ng-container>

            <div class="entry_col_last-class">
                <div class="ranking-display" *ngIf="rankings && rankings['LastClass'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.LastClass |
                            number:'1.0-0'}}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div class="entry_col_avg-class">
                <div class="ranking-display" *ngIf="rankings && rankings['AverageClass'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AverageClass |
                            number:'1.0-0' }}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_PACE && isTbredTrack">
            <div class="entry_col_early-pace-1">
                <div class="ranking-display" *ngIf="rankings && rankings['AveragePaceE1'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AveragePaceE1 }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div class="entry_col_early-pace-2">
                <div class="ranking-display" *ngIf="rankings && rankings['AveragePaceE2'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AveragePaceE2 }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div class="entry_col_late-pace">
                <div class="ranking-display" *ngIf="rankings && rankings['AveragePaceLP'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.AveragePaceLP }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_PACE && isHarnessTrack">
            <div class="entry_col_early-pace-1">
                <div class="ranking-display" *ngIf="rankings && rankings['EarlyPace'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.EarlyPace }}
                        </div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div class="entry_col_early-pace-2">
                <div class="ranking-display" *ngIf="rankings && rankings['MidPace'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.MidPace }}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>

            <div class="entry_col_late-pace">
                <div class="ranking-display" *ngIf="rankings && rankings['LatePace'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.LatePace }}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
        </ng-container>

        <!-- Program Summary RunnerStats -->

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_SUMMARY && summaryRunnerStats && isTbredTrack">
            <div class="summary_col_days-off">
                <div>
                    {{ summaryRunnerStats.daysOff || '-' }}
                </div>
            </div>

            <div class="summary_col_run-style">
                <div>
                    {{ summaryRunnerStats.priorRunStyle }}{{ summaryRunnerStats.speedPoints }}
                </div>
            </div>

            <div class="summary_col_avg-speed">
                <div>
                    {{ summaryRunnerStats.avgSpeed || '-' }}
                </div>
            </div>

            <div class="summary_col_back-speed">
                <div>
                    {{ summaryRunnerStats.backSpeed || '-' }}
                </div>
            </div>

            <div class="summary_col_late-speed">
                <div>
                    {{ summaryRunnerStats.speedLastRace || '-' }}
                </div>
            </div>

            <div class="summary_col_avg-class">
                <div>
                    {{ summaryRunnerStats?.averageClass || '-' }}
                </div>
            </div>

            <div class="summary_col_prime-p">
                <div>
                    {{ summaryRunnerStats?.primePower || '-' }}
                </div>
            </div>

            <div class="summary_col_jockey-wins">
                <div>
                    {{ summaryRunnerStats.jockeyWinPercent && (summaryRunnerStats.jockeyWinPercent | percent:'1.0-0') || '-' }}
                </div>
            </div>

            <div class="summary_col_trainer-wins">
                <div>
                    {{ summaryRunnerStats.trainerWinPercent && (summaryRunnerStats.trainerWinPercent | percent:'1.0-0') || '-' }}
                </div>
            </div>

            <div class="summary_col_earnings">
                <div>
                    {{ summaryRunnerStats.totalEarnings | formatPurse }}
                </div>
            </div>
        </ng-container>

        <!-- End Program Summary RunnerStats -->

        <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_TIPS">
            <div class="entry_col_top-pick">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.topPick; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_best-bet">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.bestBet; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_hot-jockey">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.hotJockey; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_hot-trainer">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.hotTrainer; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_combo">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.hotTrainerJockeyCombo; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_key-trainer">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.trainerAngle; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_best-distance">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.bestDistance; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_horse-for-course">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.horseForCourse; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_clocker-special">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.clocker; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_exiting-key-race">
                <ng-container *ngIf="!entry.Scratched; else scratchedAngle">
                    <ng-container *ngIf="angles?.exitKey; else notChecked">
                        <i class="icon-angles-check"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="entry_col_angles">
                <div *ngIf="angles?.topPick" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Top Pick</span></div>
                <div *ngIf="angles?.bestBet" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Best Bet</span></div>
                <div *ngIf="angles?.hotJockey" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Hot Jockey</span></div>
                <div *ngIf="angles?.hotTrainer" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Hot Trainer</span></div>
                <div *ngIf="angles?.hotTrainerJockeyCombo" class="angle-list-item"><i
                        class="icon-angles-check"></i><span class="angle-label">Combo</span></div>
                <div *ngIf="angles?.trainerAngle" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Key Trainer</span></div>
                <div *ngIf="angles?.bestDistance" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Best Distance</span></div>
                <div *ngIf="angles?.horseForCourse" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Horse for Course</span></div>
                <div *ngIf="angles?.clocker" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Clocker Special</span></div>
                <div *ngIf="angles?.exitKey" class="angle-list-item"><i class="icon-angles-check"></i><span
                        class="angle-label">Exiting Key Race</span></div>
            </div>
        </ng-container>

        <ng-template #notChecked>
            <i class="icon-angles-x"></i>
        </ng-template>
        <ng-template #scratchedAngle>
            -
        </ng-template>

        <!--Columns shared across tabs-->
        <ng-template #powerRatingCol>
            <div class="entry_col_pwr-rating">
                <div class="ranking-display" *ngIf="rankings && rankings['PowerRating'] as ranking">
                    <div class="values">
                        <div class="rating" [class.top-3]="ranking.rank && ranking.rank <= 3">{{ entry.PowerRating |
                            number:'1.0-0'}}</div>
                        <div class="rank">{{ ranking.ordinal || '-' }}</div>
                    </div>
                    <cdux-ranking-bar *ngIf="!isCompact" [isHighlighted]="ranking.rank && ranking.rank <= 3"
                        [percentage]="ranking.percentage"></cdux-ranking-bar>
                </div>
            </div>
        </ng-template>

    </ng-container>
    <ng-template #paddedEntry>
        <div class="entry_col_odds"></div>
        <div class="entry_col_ml"></div>
        <div class="entry_col_pl" *ngIf="isProfitlineOddsEnabled"></div>
        <div class="entry_col_runner"></div>
        <div class="entry_col_med-weight"></div>
        <div class="entry_col_jockey"></div>
        <div class="entry_col_trainer"></div>
        <div class="entry_col_sire-dam"></div>
        <div class="entry_col_top-pick"></div>
        <div class="entry_col_best-bet"></div>
        <div class="entry_col_hot-jockey"></div>
        <div class="entry_col_hot-trainer"></div>
        <div class="entry_col_combo"></div>
        <div class="entry_col_key-trainer"></div>
        <div class="entry_col_best-distance"></div>
        <div class="entry_col_horse-for-course"></div>
        <div class="entry_col_clocker-special"></div>
        <div class="entry_col_exiting-key-race"></div>
        <div class="entry_col_angles"></div>
        <div class="entry_col_run-style"></div>
        <div class="entry_col_days-off"></div>
        <div class="entry_col_avg-speed"></div>
        <div class="entry_col_avg-distance"></div>
        <div class="entry_col_best-speed"></div>
        <div class="entry_col_prime-p"></div>
        <div class="entry_col_last-class"></div>
        <div class="entry_col_avg-class"></div>
        <div class="entry_col_early-pace-1"></div>
        <div class="entry_col_early-pace-2"></div>
        <div class="entry_col_late-pace"></div>
    </ng-template>
</div>

<div class="entry-accordion" *ngIf="isShowingOddsTrend" [ngClass]="checkioClass"
    [class.is-advanced]="section === ViewSectionEnum.PROGRAM_ADVANCED" [class.is-compact]="isCompact">
    <div class="entry_col_num">&nbsp;</div>
    <div class="entry_col_checkio">&nbsp;</div>
    <div class="entry_col_trends">
        <div class="main-detail">TREND</div>
        <div *ngFor="let odds of oddsTrendArray" class="main-detail" [ngSwitch]="areOddsTrending(odds)">
            <div *ngSwitchCase="1" class="trend-up-chevron"></div>
            <div *ngSwitchCase="-1" class="trend-down-chevron"></div>
            {{ odds.oddsText | trimSlash }}
        </div>
    </div>
</div>
<div [class.entry-accordion]="(showingDetailsType && !entry.Scratched)">
    <ng-template #detailsContainer></ng-template>
</div>
