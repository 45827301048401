import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { enumFeatureToggle, FeatureToggleDataService } from '@cdux/ng-common';
import { IAccountHistoryRoute, RoutingOptions } from '@cdux/ng-fragments';
import { TournamentsSessionService } from '../../shared/tournaments-session/services/touranments-session.service';


@Component({
    selector: 'cdux-account-history-container',
    styles: [':host(cdux-account-history-container) { width: 100%; }'],
    template: `<cdux-account-history
        [isTournamentSelected]="isTournamentSelected"
        [toggleConfig]="toggleConfig"
        (routeChange)="onRouteChange($event)"></cdux-account-history>`
})
export class AccountHistoryContainerComponent implements OnInit, OnDestroy {
    private _subs: Subscription[] = [];

    public toggleConfig = {
        isAccountHistoryExportToggledOn: false,
        isAccountHistoryFilterToggledOn: false,
        isAccountHistoryIvrWagerMsgToggleOn: false,
        isAccountHistoryRangeFTOn: false
    }

    public isTournamentSelected = this._tournamentsSessionService.isTournamentSelected();

    constructor(private _router: Router,
                private _featureToggleService: FeatureToggleDataService,
                private _tournamentsSessionService: TournamentsSessionService
            ) {}

    ngOnInit() {
        this._subs.push(
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.ACCT_HISTORY).subscribe(
                ft => this.toggleConfig.isAccountHistoryRangeFTOn = ft
            ),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.ACCT_HISTORY_EXPORT).subscribe(
                ft => this.toggleConfig.isAccountHistoryExportToggledOn = ft
            ),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.ACCT_HISTORY_FILTER).subscribe(
                ft => this.toggleConfig.isAccountHistoryFilterToggledOn = ft
            ),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.IVR_WAGER_MSG).subscribe(
                ft => this.toggleConfig.isAccountHistoryIvrWagerMsgToggleOn = ft
            )
        );
    }

    ngOnDestroy() {
        this._subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public onRouteChange(routeObj: IAccountHistoryRoute) {
        switch (routeObj.view) {
            case RoutingOptions.PROGRAM:
                this._goToProgram(routeObj);
                break;
            case RoutingOptions.RESULTS:
                this._goToResults(routeObj);
                break;
            case RoutingOptions.VIDEO:
                this._goToProgram(routeObj, true);
                break;
        }
    }

    private _goToProgram(routeObj: IAccountHistoryRoute, isVideo: boolean = false) {
        const urlParams = ['program', routeObj.title, routeObj.brisCode, routeObj.trackType, routeObj.raceNum];
        if (isVideo) { urlParams.push('video'); }
        this._router.navigate(urlParams);
    }

    private _goToResults(routeObj: IAccountHistoryRoute) {
        if (!!routeObj.brisCode && !!routeObj.trackType && !!routeObj.raceNum) {
            this._router.navigate(['results', routeObj.title, routeObj.eventDate, routeObj.brisCode, routeObj.trackType, routeObj.raceNum]);
        } else {
            this._router.navigate(['results', routeObj.eventDate]);
        }
    }
}
