<ng-container *ngIf="fpwEnabled; else sidebarTemplate">
    <header class="funding-content_header">
        <!-- back arrow hidden on desktop -->
        <ng-container *ngIf="isSmallGlass">
          <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
          <i class="icon--close-light" (cduxTap)="close()" id="withdraw-close-btn"></i>
        </ng-container>
        <h1>EZMoney</h1>
        <div class="funding-type-icon">
            <i class="icon-ezmoney"></i>
        </div>
    </header>

    <form [formGroup]="form" *ngIf="form">
      <cdux-funding-input-amount
        id="depositAmount"
        formControlName="amount"
        [inputType]="OPERATION"
        [fundType]="fundingMethodDetails.fundType"
        [min]="0"
        [displayMin]="false"
        [max]="getMaxWithdrawAmount()"
        [displayMax]="false"
        [phoneEnabled]="phoneEnabled"
        [displayStyle]="enumWithdrawDisplayStyle.FULL_PAGE">
      </cdux-funding-input-amount>

      <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>

      <ng-container *ngIf="fundingMethodDetails.accountInfo; else noAccountTemplate">
        <div class="funding-content_details-block">
          <h2>bank details</h2>
          <div class="account-num form-label-hover">
            <div class="funding-field-label">Account ending in
              <div class="funding-cc-number">
                <i class="symbol--bullet__13"></i><span [innerHtml]="fundingMethodDetails.accountInfo"></span>
              </div>
            </div>
          </div>
          <div class="total">
            <span class="label">Total:</span>
            <span class="amount">{{form.get('amount').value | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
          </div>
          <div class="small-text center-text">Please allow 2-5 days for funds to appear in your account.</div>
          <div class="funding-content_details-block__footer">
            <div class="withdraw--btn large" id="withdrawButton" (click)="!pendingWithdraw && onWithdraw()" [disabled]="form.invalid || form.get('amount').value <= 0 || pendingWithdraw">Withdraw</div>
            <div class="withdraw--tandc"> By submitting above, I agree to the <a cduxTerms="terms">Terms and Conditions</a></div>
          </div>
        </div>

      </ng-container>

      <ng-template #noAccountTemplate>
        <div class="funding-content_details-block">
          <h3 class="center-text margin-top-20 ">Method not on file</h3>
          <p class="p-centered-normal">
            It looks like we don’t have an account on file for this method. Before you can withdraw funds to a bank account, it must first be used to complete at least one deposit. Your EZmoney transactions may take three to five calendar days to complete the banking process.
          </p>
        </div>
      </ng-template>
    </form>
</ng-container>

<ng-template #sidebarTemplate>
  <form class="withdraw-form-wrapper ezmoney" [formGroup]="form" *ngIf="form" [class.dark]="lockout">

    <div class="ts-message error" *ngIf="lockout">
      <p>We were unable to process your withdrawal. Your account may be setup incorrectly.</p>
      <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</span></p>
    </div>
    <ng-template #chat><cdux-chat></cdux-chat></ng-template>

    <div *ngIf="!lockout">
      <cdux-funding-input-amount id="withdrawAmount" formControlName="amount"
        [inputType]="OPERATION"
        [fundType]="fundingMethodDetails.fundType"
        [min]="0"
        [displayMin]="false"
        [max]="getMaxWithdrawAmount()"
        [phoneEnabled]="phoneEnabled"
        [displayMax]="false">
      </cdux-funding-input-amount>

        <div class="ts-message error" *ngIf="errorCode !== null">{{ errorCode | cduxTranslate:'errorcodes':true }}</div>
        <div class="ts-message success" *ngIf="successfulWithdraw && !errorCode">{{ successMessage }}</div>

      <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>

      <div *ngIf="plaidWithdrawEnabled && !fundingMethodDetails.verified" class="ts-body toc">YOU WILL LOGIN WITH YOUR BANK CREDENTIALS</div>
      <div class="ts-body toc">By submitting below, I agree to the <a id="termsAndConditionsW" (click)="openTermsAndConditions()" style="cursor: pointer;">Terms & Conditions.</a></div>
      <div class="ts-body toc">*It may take up to 5 days to post to your bank.</div>

      <div class="cdux-button-wrapper">
        <button #withdrawButton id="withDrawButton" (click)="!pendingWithdraw && onWithdraw()" [disabled]="form.invalid || form.get('amount').value <= 0 || pendingWithdraw">
          {{ buttonText }}
        </button>
      </div>
    </div>

  </form>
</ng-template>
